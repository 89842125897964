import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const TataKelolaPerusahaan = () => {
    return (
        <>
            <div className="container text-center mt-5 pt-5">
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/aspek-tata-kelola-perusahaan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Aspek Tata Kelola Perusahaan</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/laporan-tata-kelola-perusahaan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Laporan Tata Kelola Perusahaan</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/etika-bisnis-dan-kode-etik`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Etika Bisnis & Kode Etik</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/manajemen-risiko`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Manajemen Risiko</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/gratifikasi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Gratifikasi</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/sistem-manajemen-anti-penyuapan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Sistem Manajemen Anti Penyuapan</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/penerapan-apu-ppt`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Penerapan APU PPT</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/hubungan-afiliasi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Hubungan Afiliasi</NavLink>
                <NavLink to={`/tentang-kami/tata-kelola-perusahaan/pelaporan-whistle-blowing-system`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Pelaporan Whistle Blowing System</NavLink>
            </div>
            <Outlet />
        </>
    )
}

export default TataKelolaPerusahaan