import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import { IoChevronDownCircle } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import { FaWindowClose } from "react-icons/fa";
import Meta from "../../Utils/Meta";

const ContentPenghargaan = ({ setIsModalOpen, tahun }) => {
  const { i18n } = useTranslation();
  const { data: dataContent } = useFetch(
    "/profile/penghargaan/?lang=" + i18n.language + "&year=" + tahun
  );
  const [width, setWidth] = useState(window.innerWidth);
  // console.log(dataContent);
  return (
    <Swiper
      slidesPerView={width >= 750 ? 5 : 2}
      spaceBetween={20}
      scrollbar={{
        hide: false,
        draggable: true,
        dragSize: 150,
      }}
      modules={[Scrollbar]}
    >
      {dataContent &&
        dataContent.map((konten, k) => {
          return (
            <SwiperSlide key={k} className="d-flex flex-column justify-content-between" style={{ "minHeight": "400px" }}>
              <div style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen({ show: true, url: konten?.media?.preview })}>
                <img
                  src={konten?.media?.preview}
                  alt={konten?.image}
                  className="w-100 mb-3"
                />
                <b className="fw-bold text-center">{konten?.title}</b>
              </div>
              <p style={{ fontSize: 14 }} className="text-center mb-0" dangerouslySetInnerHTML={{ __html: konten?.description }}></p>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

const Penghargaan = () => {
  const [isModalOpen, setIsModalOpen] = useState({ show: false, url: '' });
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { data: dataPage } = useFetch(
    "/profile/penghargaan/page?lang=" + i18n.language
  );
  const { data: dataCategory } = useFetch(
    "/profile/penghargaan/category?lang=" + i18n.language
  );
  const metaValue = { title: dataPage ? '' + dataPage.title : 'Penghargaan', description: 'penghargaan', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      {isModalOpen.show &&
        <div className="fixed-top" style={{
          width: '100vw', height: '100vh', zIndex: '99', display: 'grid',
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}>
          <div className="d-flex justify-content-end">
            <button onClick={() => setIsModalOpen({ show: false, url: '' })} style={{ fontSize: 30, width: 'auto', backgroundColor: 'transparent', border: 'none', color: "#FFF", position: "absolute", padding: 0, marginTop: '-12px' }}>
              <FaWindowClose />
            </button>
          </div>
          <div style={{ filter: 'blur(8%)', width: '50vw', maxWidth: '400px' }}>
            <img
              src={isModalOpen.url}
              alt='Penghargaan'
              className="w-100 mb-3"
            />
          </div>
        </div>
      }
      <div className="container my-5">
        <h1 className="fw-bold text-center">{dataPage?.title}</h1>
        {dataCategory && (dataCategory.length > 0 ?
          dataCategory.map((ca, k) => {
            return (
              <div key={k}>
                <p className="mt-2">
                  <button
                    className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == k ? "bg-primary text-white" : "bg-white text-secondary")}
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target={`#collapse-${ca?.name}`}
                    aria-expanded={selected == k ? "true" : "false"}
                    onClick={() => setSelected(k)}
                  // aria-controls={`collapse-${ca?.name}`}
                  >
                    {ca?.name}
                    <IoChevronDownCircle className="fs-2" />
                  </button>
                </p>
                {selected == k &&
                  <>
                    <div className="px-3">
                      <div className="h-100">
                        <div className="w-100 h-100">
                          <div className="row py-3">
                            <ContentPenghargaan setIsModalOpen={setIsModalOpen} tahun={ca?.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            );
          })
          :
          <p style={{ textAlign: 'center' }}>Tidak ada penghargaan</p>)
        }


      </div>
    </div >
  );
};

export default Penghargaan;
