import React, { useState } from "react";
import ImgLogoJakOneW from "../../../assets/img/JakOne-w.png";
import ImgLogoJadiBeda from "../../../assets/img/jadi-beda.png";
import ImgPhone from "../../../assets/img/phone.png";
import ImgBgPhone from "../../../assets/img/landing2-min.jpg";
import ImgLanding3 from "../../../assets/img/landing3-min.jpg";
import ImgLanding5 from "../../../assets/img/landing5-min.jpg";
import ImgLanding6 from "../../../assets/img/landing6-min.jpg";
import ImgLanding7 from "../../../assets/img/landing7-min.jpg";
import ImgLanding8 from "../../../assets/img/landing8.png";
import ImgLanding10 from "../../../assets/img/landing10-min.jpg";
import ImgLanding11 from "../../../assets/img/landing11-min.jpg";
import ImgLanding12 from "../../../assets/img/landing12-min.jpg";
import Img1p from "../../../assets/img/jo4/Asset 4-01.png";
import Img2p from "../../../assets/img/jo4/Asset 4-04.png";
import Img3p from "../../../assets/img/jo4/Asset 4-02.png";
import Img4p from "../../../assets/img/jo4/Asset 4-06.png";
import Img5p from "../../../assets/img/jo4/Asset 4-07.png";
import Img6p from "../../../assets/img/jo4/Asset 4-03.png";
import Img7p from "../../../assets/img/jo4/Asset 4-05.png";
import ImgLanding4 from "../../../assets/img/jo4/Asset 4-08.png";

import Img19 from "../../../assets/img/jo9/1-min.png";
import Img29 from "../../../assets/img/jo9/2-min.png";
import Img39 from "../../../assets/img/jo9/3-min.png";
import Img49 from "../../../assets/img/jo9/4-min.png";
import Img59 from "../../../assets/img/jo9/5-min.png";
import Img69 from "../../../assets/img/jo9/6-min.png";
import Img79 from "../../../assets/img/jo9/7-min.png";
import Img89 from "../../../assets/img/jo9/8-min.png";

import BtnPlayStore from "../../../assets/img/play-store.png";
import BtnAppStore from "../../../assets/img/app-store.png";


import { BsMouse } from "react-icons/bs";
import { IoLogoGooglePlaystore, IoLogoAppleAppstore } from "react-icons/io5";
import { motion, useScroll, useTransform } from "framer-motion";
import useWindowDimensions from "../../../libs/useWindowDimensions";
import { useEffect } from "react";
import LightBoxVid from "../../../components/LightBoxVid";
import { Helmet } from 'react-helmet';
import Meta from "../../Utils/Meta";
import { metaValue } from "./metaValue";

const JakOneMobile = () => {
  const { scrollY } = useScroll();
  const { height, width } = useWindowDimensions();
  const [pScale, setPScale] = useState(2.5);
  useEffect(() => {
    let ratio = width / height;
    if (ratio < 1) {
      setPScale(1.35);
    } else {
      setPScale(2.5);
    }
    // console.log(width / height);
  }, [width, height]);

  const y1st = useTransform(scrollY, [0, 20], ["75vh", "100vh"]);
  const opacity1st = useTransform(scrollY, [0, 200], [1, 0]);
  const scale1st = useTransform(scrollY, [50, 200], [1, 0]);
  const scale2nd = useTransform(scrollY, [70, 200], [1, 1]);
  const opacityCaption2nd = useTransform(scrollY, [0, 200, 250], [0, 0, 1]);
  const yCaption2nd = useTransform(scrollY, [0, 200], ["10vh", "0vh"]);
  const y2nd = useTransform(scrollY, [0, 50], ["125vh", "-15vh"]);
  // const opacity2nd = useTransform(scrollY, [0, 50, 200, 250], [0, 1, 1, 0]);
  const opacityBg2nd = useTransform(
    scrollY,
    [0, 400, 600, 700],
    [0, 0, 0.8, 1]
  );

  //   const handleScroll = () => {
  //     // console.log(window.pageYOffset)
  //   }

  //   useEffect(() => {
  //     window.addEventListener('scroll', handleScroll, { passive: true });

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  const [StartX3P, setStartX3P] = useState(1000);
  const [StopX3P, setStopX3P] = useState(1200);
  const [mlP, setmlP] = useState("0%");

  useEffect(() => {
    if (height > 400 && height <= 500) {
      setStartX3P(1500);
      setStopX3P(1700);
    } else if (height > 500 && height <= 600) {
      setStopX3P(2700);
      setStartX3P(2500);
    } else if (height > 600 && height <= 700) {
      setStopX3P(3200);
      setStartX3P(3000);
    } else if (height > 700 && height <= 800) {
      setStopX3P(3400);
      setStartX3P(3200);
    } else if (height > 800 && height <= 900) {
      setStopX3P(3700);
      setStartX3P(3500);
    } else if (height > 900 && height <= 1000) {
      setStartX3P(4000);
      setStopX3P(4200);
    } else if (height > 1000 && height <= 1100) {
      setStartX3P(4700);
      setStopX3P(4900);
    } else if (height > 1100) {
      setStartX3P(6000);
      setStopX3P(6200);
    }
  }, [height, width]);

  // const x3rdp1 = useTransform(scrollY, [5000, 5500], [0, "85%"]);
  const xImg2p = useTransform(scrollY, [1900, 2100], ["0%", "62%"]);
  const xImg3p = useTransform(scrollY, [1900, 2100], ["0%", "125%"]);
  const xImg4p = useTransform(scrollY, [1900, 2100], ["0%", "180%"]);
  const xImg5p = useTransform(scrollY, [1900, 2100], ["0%", "235%"]);
  const xImg6p = useTransform(scrollY, [1900, 2100], ["0%", "285%"]);
  const xImg7p = useTransform(scrollY, [1900, 2100], ["0%", "330%"]);

  const xImg2pMobile = useTransform(scrollY, [1900, 2000], ["30%", "0%"]);
  const xImg3pMobile = useTransform(scrollY, [1900, 2000], ["-20%", "0%"]);
  const xImg4pMobile = useTransform(scrollY, [1900, 2000], ["0%", "-40%"]);
  const xImg5pMobile = useTransform(scrollY, [1900, 2000], ["0%", "50%"]);

  const [showVid, setShowVid] = useState(false);
  const [vidURL, setVidURL] = useState("");


  return (
    <div className="bg-primary">
      <Meta data={metaValue} />
      <LightBoxVid
        //untuk embed-video-url bdki
        //show berisi state boolean (true or false)
        show={showVid}
        //ketika klik button close maka set state show
        //video menjadi false sehingga video akan tertutup
        closeVid={() => setShowVid(false)}
        //url vide dari API. Berisi URL video youtube
        vidUrl={vidURL}
      />

      {/* frame 1 and frame 2 */}
      <section
        style={{ height: "200vh", overflowY: "visible", zIndex: 60 }}
        className="w-100"
      >
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-center"
          style={{
            height: "100vh",
            position: "sticky",
            top: "0px",
          }}
        >
          <motion.img
            src={ImgLogoJakOneW}
            alt="logo jakone w"
            className="logo-jw"
            style={{
              y: "45vh",
              scale: scale1st,
              // opacity: opacity1st,
            }}
          />
          <motion.div
            className="text-center"
            style={{ y: y1st, opacity: opacity1st }}
          >
            <BsMouse className="fw-bold text-white fs-1" />
            <p className="fw-600 text-white mt-2 mb-5">Scroll To Continue</p>
          </motion.div>
          <motion.div
            className="text-white fs-2 fw-semibold"
            style={{
              zIndex: 60,
              y: yCaption2nd,
              opacity: opacityCaption2nd,
            }}
          >
            #BISA
          </motion.div>
          <motion.img
            src={ImgPhone}
            className="d-flex justify-content-center align-items-center flex-column"
            style={{
              height: "65vh",
              width: "auto",
              scale: scale2nd,
              y: y2nd,
              // opacity: opacity2nd,
              opacity: 0,
              marginTop: 100,
              zIndex: 60,
            }}
            whileInView={{
              opacity: 1,
            }}
          />
          <motion.div
            style={{
              backgroundImage: `url(${ImgBgPhone})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              minWidth: "100%",
              height: "100vh",
              position: "absolute",
              opacity: opacityBg2nd,
              top: 0,
              left: 0,
              right: 0,
              // zIndex: 0,
              // x: xRight,
            }}
          >
            <div className="w-100 h-100 bg-jo-t"></div>
          </motion.div>
        </div>
      </section>
      {/* frame 3  */}
      <section
        className="w-100 vh-100"
        style={{
          backgroundImage: `url(${ImgLanding3})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex flex-column align-items-start justify-content-center h-100 bg-jo-b">
          <div className="row w-100">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6 text-center text-white">
              <h1 className="fw-semibold">Saatnya Beralih ke</h1>
              <img
                src={ImgLogoJakOneW}
                className="w-75"
                alt="jakone mobile logo"
              />
              <p className="my-4">
                JakOne Mobile memberikan kemudahan bertransaksi dan mengatur
                kebutuhan keuangan Anda dengan fitur Mobile Banking dan E-Wallet
                dalam satu aplikasi
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* frame 4 */}
      <section
        className="w-100"
        style={{
          height: "120vh",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: "110vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "sticky",
            top: "0px",
            justifyContent: "start",
            alignItems: "center",
            overflowX: "hidden",
            overflowY: "visible",
            backgroundImage: `url(${ImgLanding4})`,
            backgroundPosition: "top",
            backgroundSize: "strech",
          }}
        >
          {width >= 900 &&
            <div
              style={{
                width: "70%",
                maxWidth: '80%',
                height: "75vh",
                marginTop: "15vh",
              }}
              className="position-relative d-flex justify-content-center"
            >
              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  x: xImg7p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 0.7,
                }}
                src={Img7p}
                alt=""
              />

              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  x: xImg6p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 0.75,
                }}
                src={Img6p}
                alt=""
              />

              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  x: xImg5p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 0.8,
                }}
                src={Img5p}
                alt=""
              />

              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  x: xImg4p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 0.85,
                }}
                src={Img4p}
                alt=""
              />
              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  y: 3,
                  x: xImg3p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 1,
                }}
                src={Img3p}
                alt=""
              />
              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  x: xImg2p,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                  scale: 0.95,
                }}
                src={Img2p}
                alt=""
              />
              <motion.img
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "24%",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 0,
                  marginRight: "auto",
                }}
                src={Img1p}
                alt=""
              />
            </div>
          }
          {width < 900 && width >= 500 &&
            <div
              style={{
                width: "70%",
                maxWidth: '70%',
                height: "75vh",
                marginTop: "15vh",
              }}
              className="position-relative d-flex justify-content-center"
            >
              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "40%",
                  left: -50,
                  top: 180,
                  x: xImg4pMobile,
                }}
                src={Img4p}
                alt=""
              />

              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "40%",
                  right: -50,
                  top: 180,
                  x: xImg5pMobile,
                }}
                src={Img5p}
                alt=""
              />
              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "50%",
                  left: -50,
                  top: 120,
                  x: xImg2pMobile,
                }}
                src={Img2p}
                alt=""
              />

              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "55%",
                  right: -50,
                  top: 120,
                  x: xImg3pMobile,
                }}
                src={Img3p}
                alt=""
              />

              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "60%",
                  left: "50%",
                  top: -100,
                  bottom: 0,
                  transform: "translateX(-50%)",
                }}
                src={Img1p}
                alt=""
              />

            </div>
          }
          {width < 500 &&
            <div
              style={{
                width: "70%",
                maxWidth: '80%',
                height: "75vh",
                marginTop: "15vh",
              }}
              className="position-relative d-flex justify-content-center"
            >
              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "50%",
                  left: -50,
                  top: 120,
                  x: xImg2pMobile,
                }}
                src={Img2p}
                alt=""
              />

              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "55%",
                  right: -50,
                  top: 120,
                  x: xImg3pMobile,
                }}
                src={Img3p}
                alt=""
              />

              <motion.img
                style={{
                  margin: "auto",
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: "70%",
                  left: "50%",
                  top: -100,
                  bottom: 0,
                  transform: "translateX(-50%)",
                }}
                src={Img1p}
                alt=""
              />

            </div>
          }
        </div>
        {/* </div> */}
      </section>

      {/* frame 5 */}
      <section
        className="w-100"
        style={{
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          className="vh-100 "
          style={{
            position: "sticky",
            top: "0px",
            backgroundImage: `url(${ImgLanding5})`,
            backgroundPosition: width >= 500 ? "center center" : "0px",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column align-items-start justify-content-center w-100 h-100 bg-jo-t">
            <div className="row w-100">
              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6 text-center text-white">
                <img
                  src={ImgLogoJakOneW}
                  className="w-75"
                  alt="jakone mobile logo"
                />
                <p className="text-white my-4">
                  JakOne Mobile memberikan kemudahan bertransaksi dan mengatur
                  kebutuhan keuangan Anda dengan fitur Mobile Banking dan
                  E-Wallet dalam satu aplikasi
                </p>
                <br />
                <button
                  className="btn btn-jakone"
                  onClick={() => {
                    setVidURL("https://youtu.be/JLt284bHpIY");
                    setShowVid(true);
                  }}
                >
                  Watch Video
                </button>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 6 */}
      <section
        className="w-100"
        style={{
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          className="vh-100"
          style={{
            position: "sticky",
            top: "0px",
            backgroundImage: `url(${ImgLanding6})`,
            backgroundPosition: width >= 500 ? "center center" : "500px",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column align-items-start justify-content-center w-100 h-100 bg-jo-b">
            <div className="row w-100">
              <div className="col-12 col-md-6 text-center text-white">
                <h1>Bersiap Untuk</h1>
                <img
                  src={ImgLogoJadiBeda}
                  className="w-75"
                  alt="jakone mobile logo"
                />
                <p className="text-white my-4">
                  Nikmati experince lebih dalam bertransaksi digital menggunakan
                  Mobile Banking Bank DKI dengan fitur lengkap-lengkap
                </p>
              </div>
              <div className="col-12 col-md-6"></div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 7 */}
      <section
        className="w-100"
        style={{
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          className="w-100 vh-100"
          style={{
            position: "sticky",
            top: "0px",
            backgroundImage: `url(${ImgLanding7})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column align-items-start justify-content-center h-100 w-100 bg-jo-t">
            <div className="row w-100">
              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6 text-center text-white">
                <h1>
                  Aktifitas Perbankan dalam Genggaman, Ga Perlu bawa dompet
                </h1>
                <p className="text-white my-4">
                  Optimalkan aktifitas perbankan Anda seperti Transfer Dana,
                  Pembayaran, Cek Saldo dan lain sebagainya melalui Aplikasi
                  JakOne Mobile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 8  */}
      <section
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <div
          className="w-100"
          style={{
            backgroundImage: `url(${ImgLanding8})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            height: "100vh",
            position: "sticky",
            top: "0px",
          }}
        >
          <div className="bg-jo-b pt-5 position-relative">
            <div className="container w-100 position-sticky vh-100">
              <div className="text-start pb-3 pt-4" style={{ height: "20%" }}>
                <h1 className="fw-bold text-white mt-2">
                  Ragam Fitur & Layanan
                </h1>
              </div>
              <div
                className="row pb-5"
                style={{ height: "70%", overflow: "auto" }}
              >
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Pulsa.png")}
                      alt="Pulsa.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Pulsa</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Pembayaran.png")}
                      alt="Pembayaran.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Pembayaran</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/PaketData.png")}
                      alt="PaketData.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Paket Data</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Internet.png")}
                      alt="Internet.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Internet</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/TV.png")}
                      alt="TV.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>TV</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Pendidikan.png")}
                      alt="Pendidikan.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Pendidikan</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/PDAM.png")}
                      alt="PDAM.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>PDAM</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/PLN.png")}
                      alt="PLN.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>PLN</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Asuransi.png")}
                      alt="Asuransi.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Asuransi</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/BPJS.png")}
                      alt="BPJS.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>BPJS</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Pajak.png")}
                      alt="Pajak.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Pajak</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/TambahKartu.png")}
                      alt="Tambah Kartu.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Tambah Kartu</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/JakCard.png")}
                      alt="JakCard.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>JakCard</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Pesawat.png")}
                      alt="Pesawat.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Pesawat</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Kereta.png")}
                      alt="Kereta.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Kereta</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Zakat.png")}
                      alt="Zakat.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Zakat</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Transfer.png")}
                      alt="Transfer.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Transfer</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/MobileCash.png")}
                      alt="MobileCash.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Mobile Cash</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/BIFAST.png")}
                      alt="BI FAST.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>BI FAST</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/VirutalAccount.png")}
                      alt="VA.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Virtual Account</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Deposito.png")}
                      alt="Deposito.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Deposito</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Card.png")}
                      alt="Card.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Card</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Maps.png")}
                      alt="Maps.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Maps</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/Kontak.png")}
                      alt="Kontak.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>Kontak</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/FAQ.png")}
                      alt="FAQ.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>FAQ</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/JakOne Pay.png")}
                      alt="JO Pay.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>JakOne Pay</div>
                  </div>
                </div>
                <div className="card-icon-wraper">
                  <div className="p-2 h-100 w-100 rounded-3 shadow d-flex flex-column justify-content-between align-items-center card-icon bg-info">
                    <img
                      src={require("../../../assets/img/3D/E-Statemet.png")}
                      alt="E-Statment.png"
                      className="w-75 h-auto"
                    />
                    <div className="text-secondary fw-bold text-center" style={{ fontSize: 12 }}>E-Statment</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 9 */}
      <section style={{ width: "100%", height: "120vh", position: "relative" }}>
        <div
          className="w-100 bg-primary overflow-hidden"
          style={{
            height: "100vh",
            position: "sticky",
            top: 100,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
            className="position-relative"
          >
            <motion.img
              initial={{
                x: 0,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img19}
              whileInView={{
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: 0,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                y: 350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img29}
              whileInView={{
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: 0,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                y: -350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img39}
              whileInView={{
                opacity: 1,
              }}
              className="img-gr-t"
              alt=""
            />
            <motion.img
              initial={{
                x: 125,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img49}
              whileInView={{
                x: 200,
                opacity: 1,
              }}
              alt=""
            />

            <motion.img
              initial={{
                x: 125,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img59}
              whileInView={{
                x: 200,
                opacity: 1,
              }}
              alt=""
            />

            <motion.img
              initial={{
                x: -125,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img69}
              whileInView={{
                x: -200,
                opacity: 1,
              }}
              alt=""
            />

            <motion.img
              initial={{
                x: -125,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img79}
              whileInView={{
                x: -200,
                opacity: 1,
              }}
              alt=""
            />

            <motion.img
              initial={{
                x: 325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img49}
              whileInView={{
                opacity: 1,
                x: 400,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: 325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img29}
              whileInView={{
                x: 400,
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: 325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img59}
              whileInView={{
                x: 400,
                opacity: 1,
              }}
              className="img-gr-t"
              alt=""
            />
            <motion.img
              initial={{
                x: -325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img89}
              whileInView={{
                opacity: 1,
                x: -400,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: -325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img49}
              whileInView={{
                x: -400,
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: -325,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -350,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img29}
              whileInView={{
                x: -400,
                opacity: 1,
              }}
              className="img-gr-t"
              alt=""
            />
            <motion.img
              initial={{
                x: 525,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img39}
              whileInView={{
                x: 600,
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: 525,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img29}
              whileInView={{
                x: 600,
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: -525,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: 175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img39}
              whileInView={{
                x: -600,
                opacity: 1,
              }}
              alt=""
            />
            <motion.img
              initial={{
                x: -525,
              }}
              transition={{
                delay: 0.25,
              }}
              style={{
                position: "absolute",
                width: width >= 500 ? 150 : 165,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,

                y: -175,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={Img59}
              whileInView={{
                x: -600,
                opacity: 1,
              }}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* frame 10 */}
      <section
        style={{
          height: "100vh",
          width: "100%",
        }}
      >
        <div
          className="w-100 vh-100"
          style={{
            position: "sticky",
            top: "0px",
            backgroundImage: `url(${ImgLanding10})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column align-items-start justify-content-center h-100 bg-jo-t">
            <div className="row w-100">
              <div className="col-12 col-md-6 text-center text-white mx-3">
                <h1>Aplikasi Pembayaran Tagihan Tak Pernah Selengkap ini</h1>
                <p className="text-white my-4">
                  Segala kebutuhan pembayaran tagihan bisa dilakukan dalam
                  genggaman
                </p>
              </div>
              <div className="col-12 col-md-6"></div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 11 */}
      <section
        style={{
          height: "100vh",
          width: "100%",
          marginBottom: 50,
        }}
      >
        <div
          className="w-100 vh-100"
          style={{
            position: "sticky",
            top: "0px",
            backgroundImage: `url(${ImgLanding11})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column align-items-start justify-content-center h-100 bg-jo-t">
            <div className="row w-100">
              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6 text-center text-white">
                <h1>Mudahkan Membayar dengan Fitur scan to Pay</h1>
                <p className="text-white my-4">
                  Scan, Confirm, Lunas. Tak perlu lagi membawa cash dalam
                  dompet, tak ada lagi dompet penuh dengan koin kembalian dan
                  struk pembayaran. Nikmati kemudahan transaksi belanja di
                  jutaan merchant se-Indonesia dengan Scan QRIS melalui JakOne
                  Mobile
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* frame 12 */}
      <section
        className="w-100 vh-100 mt-5 img-gr-t"
        style={{
          backgroundImage: `url(${ImgLanding12})`,
          backgroundPosition: width >= 900 ? "center center" : (width >= 500 ? "-170px" : "-200px"),
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex flex-column align-items-start justify-content-center h-100  bg-jo-t">
          <div className="row w-100">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6 text-center text-white">
              <h1>Mewujudkan Masyarakat Non Tunai di DKI Jakarta</h1>
              <p className="text-white my-4">
                JakOne Mobile memberi kemudahan bertransaksi dan mengatur
                kebutuhan keuangan Anda dengan fitur Mobile Banking dan E-Wallet
                dalam satu aplikasi.
              </p>
              <br />
              <img src={BtnPlayStore} alt="btn-playstore" style={{
                width: 175,
                cursor: "pointer",
                margin: 2

              }}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/search?q=jakone&c=apps",
                    "_blank",
                    "noreferrer"
                  )
                }
              />
              <img src={BtnAppStore} alt="btn-playstore" style={{
                width: 175,
                cursor: "pointer",
                margin: 2
              }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/id/app/jakone-mobile/id1627959038",
                    "_blank",
                    "noreferrer"
                  )
                } />
              {/* <button
                role="link"
                className="btn btn-jakone mb-2 mx-1"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/search?q=jakone&c=apps",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Download Now <IoLogoGooglePlaystore className="fs-3 ms-1" />
              </button>
              <button
                role="link"
                className="btn btn-jakone mb-2 mx-1"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/id/app/jakone-mobile/id1627959038",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Download Now <IoLogoAppleAppstore className="fs-3 ms-1" />
              </button> */}
              <br />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JakOneMobile;
