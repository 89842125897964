import React from "react";
import LogoBankDKI from "../../assets/img/logo.svg";

const PreLoader = () => {
    return (
        <div className="d-flex flex-column vh-100 justify-content-center align-items-center">
            <img src={LogoBankDKI} width={200} height={100} alt="..." />
            <div>
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
};

export default PreLoader;
