import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import BG1 from "../../assets/img/kpr-bg.jpg";
import BG2 from "../../assets/img/kmg-bg.jpg";
import FormKPR from "./FormKPR";
import FormKMG from "./FormKMG";
import Meta from "../Utils/Meta";

const Kredit = () => {
  const { jenis } = useParams();
  const navigate = useNavigate();
  const metaValue = { title: 'Simulasi Kredit (KPR/KMG)', description: 'Simulasi Kredit (KPR/KMG)', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  const handlePush = (e) => {
    navigate("/kalkulator-finansial/kredit/" + e);
  }

  return (
    <div className="container-fluid mt-5 pt-2 bg-white">
      <Meta data={metaValue} />
      <div className="row">
        <div className="col-lg-7">
          <div className="mx-5">
            <h1 className="fw-bold mb-5">Kalkulator Finansial</h1>
            <div className="mb-5">
              <Link
                to="/kalkulator-finansial/deposito"
                className="btn btn-outline-secondary me-2  px-3 py-2 fs-6 fw-bold"
              >
                Deposito
              </Link>
              <Link
                to="/kalkulator-finansial/kredit/kpr"
                className="btn btn-secondary ms-2 px-3 py-2 fs-6  fw-bold"
              >
                KPR/KMG
              </Link>
            </div>
            <h4 className="fw-bold">Simulasi {jenis.toUpperCase()}</h4>
            {/* <div className="position-relative mt-5 pb-5 pb-md-0">
              <div className="position-absolute end-0 top-0">
                <NavLink
                  to="/kalkulator-finansial/kredit/kpr"
                  className="btn btn-outline-dark border-0 btn-kalfin me-1  px-3 py-1 fw-bold"
                >
                  KPR
                </NavLink>
                <NavLink
                  to="/kalkulator-finansial/kredit/kmg"
                  className="btn btn-outline-dark border-0 btn-kalfin ms-1 px-3 py-1 fw-bold"
                >
                  KMG
                </NavLink>
                </div>
              </div> */}
            <select onChange={(e) => handlePush(e.target.value)} value={jenis} className="d-block text-dark form-control border-0 bg-info fw-bold mt-4 p-3">
              <option value='kpr'>
                Kredit Pemilikan Rumah (KPR)
              </option>
              <option value='kmg'>
                Kredit Multi Guna (KMG)
              </option>
            </select>
            {jenis === "kpr" ? (
              <FormKPR />
            ) : jenis === "kmg" ? (
              <FormKMG />
            ) : null}
          </div>
        </div>
        <div
          className="col-lg-5"
          style={{
            backgroundImage: `url(${jenis === "kpr" ? BG1 : BG2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            maxHeight: 800,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Kredit;
