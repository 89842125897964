import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";
import Meta from '../../Utils/Meta';
import { themePagination } from '../../../utils/themePagination';

const ManagementResiko = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/tata-kelola-perusahaan/manajemen-risiko/page?lang=" + i18n.language);
  const { data: dataContent } = useFetch("/tata-kelola-perusahaan/manajemen-risiko?lang=" + i18n.language);
  const [startIndex, setStartIndex] = useState(0);


  let dataWithPagination = () => {
    let nestedArr = [];
    if (dataContent) {
      for (let i = 0; i < dataContent.length; i += 8) {
        nestedArr.push(dataContent.slice(i, i + 8));
      }
    }
    return nestedArr
  }
  let dataWithPagination_ = dataWithPagination()
  const metaValue = { title: dataPage ? '' + dataPage.title : 'Manajemen Resiko', description: dataPage ? dataPage.description?.substr(0, 255) : 'Manajemen Resiko', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <h1 className='fw-bold'>{dataPage?.title}</h1>
        <p style={{ textAlign: 'justify' }} className='text-light fs-6'>
          {dataPage?.description}
        </p>
        <div className='my-3 d-flex flex-column align-items-center justify-content-center'>
          <div className="w-100">
            <h3 className='text-secondary fw-bold'>Profil Risiko Bank DKI</h3>
            <table className='table mt-3 w-100 mt-5'>
              <thead className='table-primary'>
                <tr>
                  <th>Nama</th>
                  {/* <th className='text-light'>Bulan</th>
                  <th className='text-light'>Tahun</th> */}
                  <th className='text-light' style={{ width: 200 }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  dataContent && dataContent.map((konten, k) => {
                    return (
                      <tr key={k}>
                        <td className='fw-bold h5 py-3'>
                          {konten?.title}
                        </td>
                        {/* <td className='fw-bold h5 py-3'>
                          {konten?.month}
                        </td>
                        <td className='fw-bold h5 py-3'>
                          {konten?.year}
                        </td> */}
                        <td>
                          <button className='btn btn-primary fw-bold px-4 py-2' data-bs-toggle="modal" data-bs-target={`#modal-${konten?.id}`}>View</button>
                          <div className="modal fade" id={`modal-${konten?.id}`} tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <p dangerouslySetInnerHTML={{ __html: konten?.description }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
            {dataWithPagination_.length > 1 &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <ThemeProvider theme={themePagination}>
                  <Pagination count={dataWithPagination_.length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
                </ThemeProvider>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagementResiko