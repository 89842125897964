import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';

const StrukturGrupPerusahaan = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/profile/struktur-grup/page?lang=" + i18n.language);
  const { data: dataContent } = useFetch("/profile/struktur-grup?lang=" + i18n.language);

  const metaValue = { title: dataPage ? '' + dataPage.title : 'Artikel', description: 'ini deskripsi struktur grup perusahaan', ogimage: dataPage ? dataPage.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5 text-center">
        <h1 className='fw-bold mb-5'>
          {dataPage?.title}
        </h1>
        <img src={dataPage?.image} alt="s-g-p" className='w-100' style={{ "maxWidth": "500px" }} />
      </div>
      <div className="container mb-5">
        <div className="row">
          {
            dataContent && dataContent.map((konten, k) => {
              return (
                <div key={k} className="col-12 col-md-6 col-lg-4 p-3">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <div className="d-flex flex-column justify-content-between">
                        <div style={{ minHeight: 100 }}>
                          <h4 className='fw-bold mt-2'>
                            {konten?.title}
                          </h4>
                          <img src={konten?.media?.original} alt={konten?.image} className="w-25 my-2" />
                        </div>
                        <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: konten?.description }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default StrukturGrupPerusahaan