import React from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import Meta from "../../Utils/Meta";
import { limitText } from "../../../libs/testModification";

const SejarahDanProfilBankDKI = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/about-us/history-profile/page?lang=" + i18n.language
  );
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch(
    "/about-us/history-profile?lang=" + i18n.language
  );
  const metaValue = { title: dataPage ? '' + dataPage?.title : 'Detail', description: dataPage ? limitText(dataPage?.deskripsi, 255) : 'ini deskripsi sejarah dan profil', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div className="bg-white">
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-lg-5">
            <h1 className="fw-bold mb-5">{dataPage?.title}</h1>
            <p
              className="text-light fs-6"
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: dataPage?.deskripsi }}
            ></p>
          </div>
          <div className="col-12 col-lg-7">
            <nav>
              <div
                className="nav nav-tabs w-100 tk border-0"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id={`nav-profil-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-profil`}
                  type="button"
                  role="tab"
                  aria-controls="nav-profil"
                  aria-selected="true"
                  style={{ fontSize: 11 }}
                >
                  Profil PT Bank DKI
                </button>
                <span style={{ width: 10 }} />
                <button
                  className="nav-link"
                  id={`nav-bidang-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-bidang`}
                  type="button"
                  role="tab"
                  aria-controls="nav-bidang"
                  aria-selected="true"
                  style={{ fontSize: 11 }}
                >
                  Bidang & Kegiatan Usaha
                </button>
              </div>
            </nav>
            <div
              className="tab-content bg-white border-0 promo-content shadow"
              id="nav-tabContent"
            >
              <div
                className="tab-pane pb-5 fade show p-4 active"
                id={`nav-profil`}
                role="tabpanel"
                aria-labelledby={`nav-profil-tab`}
              >
                <p
                  style={{ fontSize: 12 }}
                  dangerouslySetInnerHTML={{ __html: dataPage?.profil_pt_bdki }}
                />
              </div>
              <div
                className="tab-pane pb-5 fade show p-4"
                id={`nav-bidang`}
                role="tabpanel"
                aria-labelledby={`nav-bidang-tab`}
              >
                <p
                  style={{ fontSize: 12 }}
                  dangerouslySetInnerHTML={{
                    __html: dataPage?.bidang_kegiatan_usaha,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-5">
          {dataContent &&
            dataContent.map((sejarah, k) => {
              return (
                <div key={k} className="col-12 col-md-6 col-lg-4 p-2">
                  <div className="card h-100">
                    <div className="container p-4">
                      <div className="">
                        <div className="">
                          <h1 className="fw-bold text-secondary">
                            {sejarah?.year}
                          </h1>
                        </div>
                        <div className="">
                          <h5 className="fw-bold" style={{ minHeight: 80 }}>{sejarah?.title}</h5>
                        </div>
                        <div className="col-12 mt-3">
                          <p
                            className="text-light fs-6"
                            // style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: sejarah?.description,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SejarahDanProfilBankDKI;
