import { useState, useEffect } from "react";
import axios from "axios";

function useFetch(endpoint) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setData(null);
        setError(null);
        axios
            .get(`${process.env.REACT_APP_API}api/v1${endpoint}`, {})
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
                // // console.log('asbasj : ', res.data.data)
            })
            .catch((err) => {
                setLoading(false);
                setError("An error occurred");
            });
        return () => {};
    }, [endpoint]);

    return { data, loading, error };
}

export default useFetch;
