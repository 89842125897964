import { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function SearchLoading() {
    let [color, setColor] = useState("#A12128");

    return (
        <div style={{ width: '100%', alignContent: 'center', display: 'flex' }}>
            <ScaleLoader
                color={color}
                loading={true}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default SearchLoading;