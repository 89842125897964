import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BankDKILogo from "../../assets/img/logo.svg";
// import JakOneW from "../../assets/img/JakOne-w.png";
import { useHistory } from 'react-router-dom';

import { BsFillGridFill, BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import useFetch from "../../configs/services/useFetch";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [navBackground, setNavBackground] = useState("");
  const [navLogo, setNavLogo] = useState(BankDKILogo);
  const [isEN, setIsEN] = useState(
    localStorage.getItem("bahasa") === "en" ? true : false
  );

  const { t, i18n } = useTranslation();

  const { data: productCategory } = useFetch(
    "/product-service/category?lang=" + i18n.language
  );
  const [searchValue, setSearchValue] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate(`search?keyword=${searchValue}`);
  };

  const handleInputChange = (e) => {
    setSearchValue(e);
  };

  useEffect(() => {
    window.location.pathname !== '/search' && handleInputChange('')
  }, [window.location.pathname])
  // // console.log('nav', productCategory)

  // function GetOSurl() {
  //   var userAgent = window.navigator.userAgent,
  //     platform =
  //       window.navigator?.userAgentData?.platform || window.navigator.platform,
  //     macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  //     windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  //     iosPlatforms = ["iPhone", "iPad", "iPod"],
  //     os = null;

  //   if (macosPlatforms.indexOf(platform) !== -1) {
  //     //   os = 'Mac OS';
  //     os = "https://apps.apple.com/id/app/jakone-mobile/id1627959038";
  //   } else if (iosPlatforms.indexOf(platform) !== -1) {
  //     //   os = 'iOS';
  //     os = "https://apps.apple.com/id/app/jakone-mobile/id1627959038";
  //   } else if (windowsPlatforms.indexOf(platform) !== -1) {
  //     //   os = 'Windows';
  //     os = "https://play.google.com/store/search?q=jakone&c=apps";
  //   } else if (/Android/.test(userAgent)) {
  //     //   os = 'Android';
  //     os = "https://play.google.com/store/search?q=jakone&c=apps";
  //   } else if (/Linux/.test(platform)) {
  //     //   os = 'Linux';
  //     os = "https://play.google.com/store/search?q=jakone&c=apps";
  //   }

  //   return os;
  // }

  useEffect(() => {
    if (isEN) {
      localStorage.setItem("bahasa", "en");
      i18n.changeLanguage("en");
    } else {
      localStorage.setItem("bahasa", "id");
      i18n.changeLanguage("id");
    }
  }, [isEN]);

  const handleScroll = () => {
    const show = window.scrollY > 320;
    if (show) {
      setNavBackground("bg-white bb-shadow");
      setNavLogo(BankDKILogo);
    } else {
      setNavBackground("");
      setNavLogo(BankDKILogo);
    }
  };
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={
        "navbar navbar-expand-lg navbar-light fixed-top " + navBackground
      }
    >
      <div className="container">
        <Link className="navbar-brand" to="/home">
          <img
            src={navLogo}
            alt="..."
            width={150}
            // height="24"
            className="d-inline-block align-text-top"
          />
          {/* Bootstrap */}
        </Link>

        <div
          className="collapse navbar-collapse position-relative"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto me-lg-5 pe-lg-3 mb-2 mb-lg-0">
            <li className="nav-item px-2">

              <NavLink
                to={`produk/${productCategory && productCategory[0].id}/${productCategory && productCategory[0].name}`}
                className="nav-link fs-6"
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {productCategory && productCategory[0].name}
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink
                to="digital"
                className="nav-link fs-6"
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("digital.nav")}
              </NavLink>
            </li>
            <li className="nav-item dropdown has-megamenu px-2">
              <NavLink
                to="#"
                className="nav-link fs-6"
                data-bs-toggle="dropdown"
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "default"
                }}
              >
                {t("produk.nav")}
              </NavLink>
              <div className="dropdown-menu megamenu" role="menu">
                <div className="container card p-3 mt-2 shadow">
                  <div className="row g-3">
                    {productCategory &&
                      productCategory?.filter((p) => { return p?.id === 7 || p?.id === 8 || p?.id === 11 || p?.id === 12 }).map((pc, k) => {
                        return (
                          <div key={k} className="col-12 col-lg-6">
                            <div className="col-megamenu">
                              <h6 className="megamenu-title">
                                <Link to={`produk/${pc?.id}/${pc?.name}`}>
                                  {pc?.name}
                                </Link>
                              </h6>
                              <div className="megamenu-desc">
                                {pc?.description}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item px-2">
              <NavLink
                to="promo"
                className="nav-link fs-6"
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("promo.nav")}
              </NavLink>
            </li>
            <li className="nav-item dropdown has-megamenu px-2">
              <NavLink
                to="#"
                className="nav-link fs-6"
                data-bs-toggle="dropdown"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "default"
                }}
              >
                {t("about.nav")}
              </NavLink>
              <div className="dropdown-menu megamenu" role="menu">
                <div className="container card p-3 mt-2 shadow">
                  <div className="row g-3">
                    <div className="col-lg-4 col-12">
                      <div className="col-megamenu">
                        <h6 className="megamenu-title">
                          <Link to="tentang-kami/profil-bank-dki/sejarah-profil-bank-dki">
                            Profil Bank DKI
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="col-megamenu">
                        <h6 className="megamenu-title">
                          <Link to="tentang-kami/management-bank-dki/dewan-komisaris">
                            Management Bank DKI
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="col-megamenu">
                        <h6 className="megamenu-title">
                          <Link to="tentang-kami/tata-kelola-perusahaan/aspek-tata-kelola-perusahaan">
                            Tata Kelola Perusahaan
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="col-megamenu">
                        <h6 className="megamenu-title">
                          <Link to="tentang-kami/hubungan-investor/informasi-keuangan">
                            Hubungan Investor
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="col-megamenu">
                        <h6 className="megamenu-title">
                          <Link to="tentang-kami/csr">CSR</Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="nav-item px-2">
              <NavLink
                to="karir"
                className="nav-link fs-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("karir.nav")}
              </NavLink>
            </li> */}
            {/* <li className="nav-item px-2">
              <a
                href={GetOSurl()}
                className="nav-link fs-6 text-white bg-primary rounded-pill"
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 40,
                  marginTop: 6,
                }}
              >
                <img src={JakOneW} width={70} alt="..." />
              </a>
            </li> */}
            <li className="nav-item position-relative px-2">
              <NavLink
                to="#"
                className="nav-link fs-6"
                data-bs-toggle="dropdown"
              >
                <BsSearch />
              </NavLink>
              <ul className="dropdown-menu dropdown-menu-end search border-0 mt-2">
                <li className="p-2">
                  <form className="d-flex" onSubmit={handleFormSubmit}>
                    <input
                      type="search"
                      className="rounded-pill border-0 bg-info py-1 px-2"
                      value={searchValue}
                      onChange={e => handleInputChange(e.target.value)}
                    />
                    <button className="btn nav-link fs-6 rounded-pill py-0 px-3">
                      Search
                    </button>
                  </form>
                  {/* <form
                    className="d-flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      navigate(`search?keyword=${e.target[0].value}`);
                    }}
                  >
                    <input
                      type="search"
                      className="rounded-pill border-0 bg-info p-1"
                    />{" "}
                    <button className="btn nav-link fs-6 rounded-pill py-0 px-3">
                      Search
                    </button>
                  </form> */}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div
          className="d-flex position-absolute pt-1 end-0 mt-1"
          style={{ top: -15 }}
        >
          <label className="switch mt-3">
            <input
              type="checkbox"
              checked={isEN}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsEN(true);
                } else {
                  setIsEN(false);
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          <NavLink
            to="#"
            className="nav-link fs-6 ms-3 me-3 d-lg-none pt-3 mt-1"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsFillGridFill /> <span style={{ marginLeft: 5 }}>Menu</span>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
