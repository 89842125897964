import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsDownload } from 'react-icons/bs'
import useFetch from '../../../configs/services/useFetch'
import '../../../../src/assets/styles/input.module.css';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";
import Meta from '../../Utils/Meta'
import { themePagination } from '../../../utils/themePagination'

const LaporanTahunanBerkelanjutan = () => {
  const { i18n } = useTranslation()
  const [startIndex, setStartIndex] = useState(0);
  const [searchByName, setSearchByName] = useState('')
  const { data: dataContent } = useFetch(
    '/hubungan-investor/laporan-tahunan-berkelanjutan?lang=' + i18n.language
  )

  let dataWithPagination = () => {
    let nestedArr = [];
    if (dataContent) {
      for (let i = 0; i < dataContent.filter((e) => e.filename1.toLowerCase().includes(searchByName.toLowerCase())).length; i += 8) {
        nestedArr.push(dataContent.filter((e) => e.filename1.toLowerCase().includes(searchByName.toLowerCase())).slice(i, i + 8));
      }
    }
    return nestedArr
  }

  let dataWithPagination_ = dataWithPagination()

  const downloadFile = (url) => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
  }


  const metaValue = { title: 'Laporan Tahunan & Berkelanjutan', description: 'Laporan Tahunan & Berkelanjutan', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className='container'>
        <h1 className='fw-bold text-center mt-5 mb-5'>
          Laporan Tahunan & Berkelanjutan
        </h1>
        <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
        <div className='row'>
          {dataContent &&
            dataContent.filter((e) => e.filename1.toLowerCase().includes(searchByName.toLowerCase())).slice(startIndex, (startIndex + 8)).map((content, k) => {
              return (
                <div className='col-12 col-lg-3 p-3' key={k}>
                  <div className='h-100 w-100 d-flex flex-column'>
                    <img
                      src={content?.image}
                      alt={``}
                      className='w-100 rounded-3'
                    />
                    <div className='flex-fill d-flex flex-column justify-content-center'>
                      <div>
                        <h4 className='fw-bold mt-3'>{content?.filename1}</h4>
                        <a
                          // onClick={() => downloadFile(content?.file1)}
                          href={content?.file1}
                          className='fw-bold text-decoration-none'
                          target='_blank'
                          rel='noreferrer'
                          download={`${content?.filename1}.pdf`}
                        >
                          <BsDownload className='me-2' /> Download
                        </a>
                      </div>
                      <div>
                        <h4 className='fw-bold mt-3'>{content?.filename2}</h4>
                        <a
                          // onClick={() => downloadFile(content?.file2)}
                          href={content?.file2}
                          className='fw-bold text-decoration-none'
                          target='_blank'
                          rel='noreferrer'
                          download={`${content?.filename2}.pdf`}
                        >
                          <BsDownload className='me-2' /> Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        {dataWithPagination_.length > 1 &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ThemeProvider theme={themePagination}>
              <Pagination count={dataWithPagination_.length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
            </ThemeProvider>
          </div>
        }
      </div>
    </div>
  )
}

export default LaporanTahunanBerkelanjutan
