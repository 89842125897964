import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import GA4React, { useGA4React } from "ga-4-react";
import TagManager from 'react-gtm-module'

import PreLoader from "../../components/PreLoader";
import Layout from "../../Layout";
import ArtikelPage from "../../pages/Artikel";
import ArtikelDetail from "../../pages/Artikel/Detail";
import JakOneMobile from "../../pages/Digital/JakOneMobile";
import Deposito from "../../pages/KalkulatorFinansial/Deposito";
import Kredit from "../../pages/KalkulatorFinansial/Kredit";
import Search from "../../pages/Search";
import SiaranPersDetail from "../../pages/SiaranPers/Detail";
import CSR from "../../pages/TentangKami/CSR";
import DetailCSR from "../../pages/TentangKami/CSR/Detail";
import HubunganInvestor from "../../pages/TentangKami/HubunganInvestor";
import InformasiKeuangan from "../../pages/TentangKami/HubunganInvestor/InformasiKeuangan";
import InformasiSahamObligasi from "../../pages/TentangKami/HubunganInvestor/InformasiSahamObligasi";
import LaporanTahunanBerkelanjutan from "../../pages/TentangKami/HubunganInvestor/LaporanTahunanBerkelanjutan";
import PengumumanPublikasi from "../../pages/TentangKami/HubunganInvestor/PengumumanPublikasi";
import SiaranPers from "../../pages/TentangKami/HubunganInvestor/SiaranPers";
import ManagementBankDKI from "../../pages/TentangKami/ManagementBankDKI";
import DewanKomisaris from "../../pages/TentangKami/ManagementBankDKI/DewanKomisaris";
import DewanPengawasanSyariah from "../../pages/TentangKami/ManagementBankDKI/DewanPengawasanSyariah";
import Direksi from "../../pages/TentangKami/ManagementBankDKI/Direksi";
import Komite from "../../pages/TentangKami/ManagementBankDKI/Komite";
import PejabatEksekutif from "../../pages/TentangKami/ManagementBankDKI/PejabatEksekutif";
import ProfilBankDKI from "../../pages/TentangKami/ProfilBankDKI";
import Artikel from "../../pages/TentangKami/ProfilBankDKI/Artikel";
import BudayaKerja from "../../pages/TentangKami/ProfilBankDKI/BudayaKerja";
import IdentitasPerusahaan from "../../pages/TentangKami/ProfilBankDKI/IdentitasPerusahaan";
import LembagaProfesi from "../../pages/TentangKami/ProfilBankDKI/LembagaProfesi";
import Penghargaan from "../../pages/TentangKami/ProfilBankDKI/Penghargaan";
import SejarahDanProfilBankDKI from "../../pages/TentangKami/ProfilBankDKI/SejarahDanProfilBankDKI";
import StrukturGrupPerusahaan from "../../pages/TentangKami/ProfilBankDKI/StrukturGrupPerusahaan";
import StrukturOrganisasi from "../../pages/TentangKami/ProfilBankDKI/StrukturOrganisasi";
import VisiMisi from "../../pages/TentangKami/ProfilBankDKI/VisiMisi";
import TataKelolaPerusahaan from "../../pages/TentangKami/TataKelolaPerusahaan";
import AspekTataKelolaPerusahaan from "../../pages/TentangKami/TataKelolaPerusahaan/AspekTataKelolaPerusahaan";
import EtikaBisnisKodeEtik from "../../pages/TentangKami/TataKelolaPerusahaan/EtikaBisnisKodeEtik";
import Gratifikasi from "../../pages/TentangKami/TataKelolaPerusahaan/Gratifikasi";
import HubunganAfiliasi from "../../pages/TentangKami/TataKelolaPerusahaan/HubunganAfiliasi";
import LaporanTataKelolaPerusahaan from "../../pages/TentangKami/TataKelolaPerusahaan/LaporanTataKelolaPerusahaan";
import ManagementResiko from "../../pages/TentangKami/TataKelolaPerusahaan/ManagementResiko";
import PelaporanWhistleBlowingSystem from "../../pages/TentangKami/TataKelolaPerusahaan/PelaporanWhistleBlowingSystem";
import PenerapanApuPpt from "../../pages/TentangKami/TataKelolaPerusahaan/PenerapanApuPpt";
import SistemManajemenAntiPenyuapan from "../../pages/TentangKami/TataKelolaPerusahaan/SistemManajemenAntiPenyuapan";
import Detail from "../../pages/Digital/Detail";
// import ProdukDetail from "../../pages/Produk/Detail";

const Home = React.lazy(() => import("../../pages/Home"));
const Digital = React.lazy(() => import("../../pages/Digital"));
const DetailDigital = React.lazy(() => import("../../pages/Digital/Detail"));
const Produk = React.lazy(() => import("../../pages/Produk"));
const ProdukDetail = React.lazy(() => import("../../pages/Produk/Detail"));
const Promo = React.lazy(() => import("../../pages/Promo"));
const DetailPromo = React.lazy(() => import("../../pages/Promo/Detail"));
const Karir = React.lazy(() => import("../../pages/Karir"));
const Bantuan = React.lazy(() => import("../../pages/Bantuan"));
const JaringanLayanan = React.lazy(() => import("../../pages/JaringanLayanan"));
const KalkulatorFinansial = React.lazy(() =>
  import("../../pages/KalkulatorFinansial")
);

const ga4react = new GA4React(process.env.REACT_APP_TRACKING_ID);
const gtmreact = process.env.REACT_APP_GTM_ID;

const RootRoutes = () => {
  const location = useLocation();
  TagManager.initialize({
    gtmId: gtmreact
  })

  useEffect(() => {
    ga4react.initialize().then((ga4) => {
      ga4.pageview(location.pathname + location.search);
      ga4.gtag("event", "pageview", {});
    });
  }, [location]);

  return (
    <Routes>
      {/* temporary redirection */}
      <Route
        path="/produk/detail/40"
        element={<Navigate to="/produk/ketentuan-umum-pembukaan-rekening-bank-dki-konvensional" replace />}
    />

      <Route path="/" element={<Layout />}>
        {/* <Route index element={<Home />} /> */}
        <Route
          index
          element={
            <React.Suspense fallback={<PreLoader />}>
              {/* <Home /> */}
              <JakOneMobile />
            </React.Suspense>
          }
        />
        <Route
          path="home"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route path="digital">
          <Route
            index
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Digital />
              </React.Suspense>
            }
          />
          <Route
            path="jak-one-mobile"
            element={
              <React.Suspense>
                <JakOneMobile />
              </React.Suspense>
            }
          />
          <Route
            path=":content"
            element={
              <React.Suspense>
                <DetailDigital />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="produk">
          <Route
            path=":id/:content"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Produk />
              </React.Suspense>
            }
          />

          <Route
            path=":content"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <ProdukDetail />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="promo">
          <Route
            index
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Promo />
              </React.Suspense>
            }
          />
          <Route
            path=":content"
            element={
              <React.Suspense>
                <DetailPromo />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="tentang-kami">
          <Route path="profil-bank-dki" element={<ProfilBankDKI />}>
            <Route
              path="sejarah-profil-bank-dki"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <SejarahDanProfilBankDKI />
                </React.Suspense>
              }
            />
            <Route
              path="visi-misi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <VisiMisi />
                </React.Suspense>
              }
            />
            <Route
              path="budaya-kerja"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <BudayaKerja />
                </React.Suspense>
              }
            />
            <Route
              path="identitas-perusahaan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <IdentitasPerusahaan />
                </React.Suspense>
              }
            />
            <Route
              path="struktur-organisasi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <StrukturOrganisasi />
                </React.Suspense>
              }
            />
            <Route
              path="lembaga-profesi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <LembagaProfesi />
                </React.Suspense>
              }
            />
            <Route
              path="artikel"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <Artikel />
                </React.Suspense>
              }
            />
            <Route
              path="struktur-grup-perusahaan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <StrukturGrupPerusahaan />
                </React.Suspense>
              }
            />
            <Route
              path="penghargaan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <Penghargaan />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="management-bank-dki" element={<ManagementBankDKI />}>
            <Route
              path="dewan-komisaris"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <DewanKomisaris />
                </React.Suspense>
              }
            />
            <Route
              path="dewan-pengawasan-syariah"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <DewanPengawasanSyariah />
                </React.Suspense>
              }
            />
            <Route
              path="direksi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <Direksi />
                </React.Suspense>
              }
            />
            <Route
              path="pejabat-eksekutif"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <PejabatEksekutif />
                </React.Suspense>
              }
            />
            <Route
              path="komite"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <Komite />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path="tata-kelola-perusahaan"
            element={<TataKelolaPerusahaan />}
          >
            <Route
              path="aspek-tata-kelola-perusahaan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <AspekTataKelolaPerusahaan />
                </React.Suspense>
              }
            />
            <Route
              path="laporan-tata-kelola-perusahaan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <LaporanTataKelolaPerusahaan />
                </React.Suspense>
              }
            />
            <Route
              path="etika-bisnis-dan-kode-etik"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <EtikaBisnisKodeEtik />
                </React.Suspense>
              }
            />
            <Route
              path="manajemen-risiko"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <ManagementResiko />
                </React.Suspense>
              }
            />
            <Route
              path="gratifikasi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <Gratifikasi />
                </React.Suspense>
              }
            />
            <Route
              path="sistem-manajemen-anti-penyuapan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <SistemManajemenAntiPenyuapan />
                </React.Suspense>
              }
            />
            <Route
              path="penerapan-apu-ppt"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <PenerapanApuPpt />
                </React.Suspense>
              }
            />
            <Route
              path="hubungan-afiliasi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <HubunganAfiliasi />
                </React.Suspense>
              }
            />
            <Route
              path="pelaporan-whistle-blowing-system"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <PelaporanWhistleBlowingSystem />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="hubungan-investor" element={<HubunganInvestor />}>
            <Route
              path="informasi-keuangan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <InformasiKeuangan />
                </React.Suspense>
              }
            />
            <Route
              path="laporan-tahunan-dan-berkelanjutan"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <LaporanTahunanBerkelanjutan />
                </React.Suspense>
              }
            />
            <Route
              path="informasi-saham-dan-obligasi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <InformasiSahamObligasi />
                </React.Suspense>
              }
            />
            <Route
              path="pengumuman-dan-publikasi"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <PengumumanPublikasi />
                </React.Suspense>
              }
            />
            <Route
              path="siaran-pers"
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <SiaranPers />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="csr">
            <Route
              index
              element={
                <React.Suspense fallback={<PreLoader />}>
                  <CSR />
                </React.Suspense>
              }
            />
            <Route
              path=":content"
              element={
                <React.Suspense>
                  <DetailCSR />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="karir"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Karir />
            </React.Suspense>
          }
        />
        <Route
          path="jaringan-layanan"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <JaringanLayanan />
            </React.Suspense>
          }
        />
        <Route
          path="bantuan"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Bantuan />
            </React.Suspense>
          }
        />
        <Route
          path="kalkulator-finansial"
          // element={
          //     // <React.Suspense fallback={<PreLoader />}>
          //     <KalkulatorFinansial />
          //     // </React.Suspense>
          // }
        >
          <Route
            path="deposito"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Deposito />
              </React.Suspense>
            }
          />
          <Route
            path="kredit/:jenis"
            element={
              <React.Suspense fallback={<PreLoader />}>
                <Kredit />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path="artikel"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <ArtikelPage />
            </React.Suspense>
          }
        />
        <Route
          path="artikel/:content"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <ArtikelDetail />
            </React.Suspense>
          }
        />
        <Route
          path="siaran-pers/:content"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <SiaranPersDetail />
            </React.Suspense>
          }
        />
        <Route
          path="search"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <Search />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<PreLoader />}>
              <div className="d-flex w-100 h-100 justify-content-center align-items-center m-5 p-5">
                <h1>Page Not Found</h1>
              </div>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
