import React from 'react'
import { useTranslation } from 'react-i18next';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../configs/services/useFetch';
import Meta from '../Utils/Meta';
import { limitText } from '../../libs/testModification';

const ArtikelDetail = () => {
    const { content } = useParams();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { data: dataPage } = useFetch("/about-us/article/" + content + "?lang=" + i18n.language);
    const metaValue = { title: dataPage ? '' + dataPage.title : 'Artikel', description: dataPage ? limitText(dataPage?.description, 255) : 'Artikel', ogimage: dataPage ? dataPage.media.preview : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

    return (
        <div className="container-fluid mt-5 pt-2">
            <Meta data={metaValue} />
            <div className="">
                <div className="">
                    <img src={dataPage?.media?.preview} className="w-100" alt={dataPage?.image} style={{ maxHeight: 500, objectFit: "cover" }} />
                </div>

                <div className="mt-5 pe-lg-5" style={{ minHeight: 200 }}>
                    <div className="me-lg-5 pe-lg-5">
                        <h1 className="fw-bold text-secondary mb-4">
                            {dataPage?.title}
                        </h1>
                        <p
                            className="fs-6 text-dark"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: dataPage?.description }}
                        >
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ArtikelDetail