import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoChevronDownCircle } from 'react-icons/io5';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';

const PengumumanPublikasi = () => {
  const [selected, setSelected] = useState(0);
  const { i18n } = useTranslation();
  const { data: dataContent } = useFetch("/hubungan-investor/pengumuman-publikasi?lang=" + i18n.language + "&page=1&limit=0");
  // console.log("page: ", dataContent)

  const changeSelected = (newSelected) => {
    if (newSelected == selected) {
      setSelected(null);
    } else {
      setSelected(newSelected);
    }
  }

  const metaValue = { title: 'Pengumuman dan Publikasi', description: 'Pengumuman dan Publikasi', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container mb-5">
        <h1 className="fw-bold text-center my-5">Pengumuman dan Publikasi</h1>
        {dataContent && dataContent?.datas.map((ca, k) => {
          return (
            <div key={k}>
              <p className="mt-2">
                <a href="javascript:void(0)" style={{ textDecoration: 'none' }}>
                  <div
                    style={{ textAlign: 'left' }}
                    className={"btn text-left border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == k ? "bg-primary text-white" : "bg-white text-secondary")}
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target={`#collapse-${ca?.name}`}
                    aria-expanded={selected == k ? "true" : "false"}
                    onClick={() => changeSelected(k)}
                  >
                    <span className='w-100'>{ca?.title}</span>
                    <IoChevronDownCircle className="fs-2 ms-2" size={40} />
                  </div>
                </a>
              </p>
              {selected == k &&
                <div className="p-3">
                  <div className="h-100">
                    <div className="w-100 h-100">
                      <div className="container text-center">
                        <img src={ca?.media?.original} alt={ca?.image} className="pub-img w-100" style={{ maxWidth: 350 }} />
                      </div>

                      {/* <p className='w-100' dangerouslySetInnerHTML={{ __html: ca?.description }}></p> */}
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PengumumanPublikasi