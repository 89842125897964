import React from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../configs/services/useFetch";
import Meta from "../Utils/Meta";
import { limitText } from "../../libs/testModification";
// import P1 from "../../assets/img/promo.png";

const Detail = () => {
    const navigate = useNavigate();
    const { content } = useParams();
    const { i18n } = useTranslation();
    const { data: dataPage } = useFetch("/digital-banking/" + content + "?lang=" + i18n.language);

    const metaValue = { title: dataPage ? '' + dataPage.title : 'detail', description: dataPage ? limitText(dataPage?.description, 255) : 'ini deskripsi detail', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

    function replaceEmbedYoutube(content){
        let newContent = content.replaceAll('<figure class="media">', '');
        newContent = newContent.replaceAll('<oembed url="', '<iframe allowfullscreen scrolling="no" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" style="width: 100%; aspect-ratio: 16 / 9;" src="');
        newContent = newContent.replaceAll('</oembed>', '</iframe>');
        newContent = newContent.replaceAll('</figure>', '');
        return newContent;
    }
    return (
        <div className="container-fluid">
            <Meta data={metaValue} />
            <div className="">
                <div className="mt-5">
                    <img src={dataPage?.media?.original} className="w-100" alt={dataPage?.image} style={{ "max-height": "500px", "objectFit": "cover" }} />
                </div>
                <div className="p-5">
                    <div className="">
                        <h1 className="fw-bold  mb-4">
                            {dataPage?.title}
                        </h1>
                        <p
                            className=" fs-6"
                            // style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: dataPage?.description ? replaceEmbedYoutube(dataPage?.description) :'-' }}
                        />

                        <button onClick={() => window.open(dataPage?.link, "_blank")} className="btn btn-primary rounded-pill fw-bold fs-6 px-4 py-2 my-4 mx-2">
                            Daftar Sekarang
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
