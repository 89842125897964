export const handleChangeNull = (data) => {
  if (data && data?.datas.length > 0) {
    let copied = JSON.parse(JSON.stringify(data));
    for (var i in copied.datas) {
      if (copied.datas[i].name == null) {
        copied.datas[i].name = "";
      }
    }
    copied.type = data.datas[0].name == null ? "img" : "pdf";
    return copied;
  } else {
    return data;
  }
};
