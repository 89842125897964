import React from "react";
import "./assets/styles/App.scss";
import RootRoutes from "./configs/RootRoutes";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import './i18n'


function App() {
    return (
        <RootRoutes />
    );
}

export default App;
