import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import { AiOutlineFileDone } from "react-icons/ai";
import Meta from "../../Utils/Meta";

const Komite = () => {
  const [category, setCategory] = useState("audit");
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/about-us/commitee/page?lang=" + i18n.language
  );
  const { data: dataContent } = useFetch(
    "/about-us/commitee?lang=" + i18n.language + "&category=" + category
  );
  const metaValue = { title: dataPage ? '' + dataPage.title : 'Komite', description: dataPage ? dataPage.description?.substr(0, 255) : 'Komite', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h1 className="fw-bold">{dataPage?.title}</h1>
          </div>
          <div
            className="col-12 col-lg-8 fs-6 text-light"
            style={{
              textAlign: "justify",
            }}
          >
            {dataPage?.description}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="">
          <div className="d-flex justify-content-start flex-wrap">
            <button
              onClick={() => setCategory("audit")}
              className={
                category === "audit"
                  ? "btn text-start btn-secondary border-0 shadow my-2 col-12 col-md-4 fs-6 py-2 fw-bold"
                  : "btn text-start bg-white text-secondary border-0 shadow my-2 col-12 col-md-4 fs-6 py-2 fw-bold"
              }
            >
              <AiOutlineFileDone className="me-2" size={34} /> Komite Audit
            </button>
            <div className="px-3 col-12 col-md-4">
              <button
                onClick={() => setCategory("pemantau resiko")}
                className={
                  category === "pemantau resiko"
                    ? "btn text-start btn-secondary border-0 shadow my-2 fs-6 py-2 fw-bold w-100"
                    : "btn text-start bg-white text-secondary border-0 shadow my-2 fs-6 py-2 fw-bold w-100"
                }
              >
                <AiOutlineFileDone className="me-2" size={34} /> Komite Pemantau
                Risiko
              </button>
            </div>
            <button
              onClick={() => setCategory("remunerasi dan nominasi")}
              className={
                category === "remunerasi dan nominasi"
                  ? "btn text-start btn-secondary border-0 shadow my-2 col-12 col-md-4 fs-6 py-2 fw-bold"
                  : "btn text-start bg-white text-secondary border-0 shadow my-2 col-12 col-md-4 fs-6 py-2 fw-bold"
              }
            >
              <AiOutlineFileDone className="me-2" size={34} /> Komite Remunerasi
              & Nominasi
            </button>
          </div>
          <div className="mt-5">
            <div className="row">
              {dataContent &&
                dataContent.map((konten, k) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-3">
                      <div
                        className="w-100 m-2 text-center"
                        data-bs-toggle="modal"
                        data-bs-target={`#modal-${konten?.id}`}
                      >
                        <img
                          src={konten?.media?.original}
                          alt={konten?.image}
                          className="rounded-circle mb-3"
                          style={{ height: 200 }}
                        />
                        <h4 style={{ height: 60 }} className="fw-bold">{konten?.title}</h4>
                        <p>{konten?.jabatan}</p>
                      </div>
                      <div
                        className="modal modal-lg fade"
                        id={`modal-${konten?.id}`}
                        tabindex="-1"
                        aria-labelledby={`modal-${konten?.id}Label`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content py-3">
                            <div className="modal-body">
                              <button
                                type="button"
                                className="btn-close float-end"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                              <div className="d-flex align-items-center mx-5">
                                <div
                                  className="rounded-circle"
                                  style={{
                                    width: 100,
                                    height: 100,
                                    backgroundImage: `url(${konten?.media?.original})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                                <div className="ms-2">
                                  <h4 className="fw-bold">
                                    {konten?.title}
                                  </h4>
                                  <p>{konten?.jabatan}</p>
                                </div>
                              </div>
                              <div
                                className="mx-5 mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: konten?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Komite;
