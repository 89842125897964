import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BsDownload, BsFileEarmarkPdf } from 'react-icons/bs';
import { IoChevronDownCircle } from 'react-icons/io5';
import useFetch from '../../../configs/services/useFetch';
import imgIkhtisar from "../../../assets/img/ikhtisar.jpeg";
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";
import '../../../../src/assets/styles/input.module.css';
import Meta from '../../Utils/Meta';
import { handleData as _handleData } from './helper/handlerPagination';
import { handleChangeNull } from './helper/handleChangeNull';
import { themePagination } from '../../../utils/themePagination';

const InformasiKeuangan = () => {
  const [selected, setSelected] = useState(0);
  const { i18n } = useTranslation();
  const [searchByName, setSearchByName] = useState('')
  const [startIndex, setStartIndex] = useState(0);
  const handleData = (d) => _handleData(d, searchByName)
  const endIndex = startIndex + 8;
  const { data: dataContent1 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=ikhtisar keuangan");
  const { data: dataContent2 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan keuangan bulanan");
  const { data: dataContent3 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan keuangan tahunan");
  const { data: dataContent4 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan liquidity coverage ratio");
  const { data: dataContent5 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan net stable funding datio");
  const { data: dataContent6 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=informasi kuantitatif eksposur ratio");
  const { data: dataContent7 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan leverage ratio");
  const { data: dataContent8 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=ukuran utama");
  const { data: dataContent9 } = useFetch("/hubungan-investor/informasi-keuangan?lang=" + i18n.language + "&page=1&limit=0&category=laporan suku bunga dasar kredit");

  const changeSelected = (newSelected) => {
    if (newSelected == selected) {
      setSelected(-1);
      setStartIndex(0)
      setSearchByName('')
    } else {
      setSearchByName('')
      setSelected(newSelected);
      setStartIndex(0)
    }
  }

  const metaValue = { title: 'Informasi Keuangan', description: 'Informasi Keuangan', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 0 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            aria-expanded={selected == 0 ? "true" : "false"}
            onClick={() => changeSelected(0)}
          >
            Ikhtisar Keuangan
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 0 && <div className='px-3'>
          <div className="h-100">
            <div className='w-100 h-100'>
              <div className="container">
                <PerCatImageItems data={dataContent1} />
              </div>
            </div>
          </div>
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 1 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            aria-expanded={selected == 1 ? "true" : "false"}
            onClick={() => changeSelected(1)}
          >
            Laporan Keuangan Bulanan
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 1 && <div className='px-3'>
          <div className="h-100">
            {handleChangeNull(dataContent2)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent2)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent2)?.type == 'img' ? <PerCatImageItems data={dataContent2} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent2))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent2)).length > 1 && handleChangeNull(dataContent2)?.type == 'pdf' && handleChangeNull(dataContent2)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent2)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 2 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 2 ? "true" : "false"}
            onClick={() => changeSelected(2)}
          // aria-controls={`collapse-${ca?.name}`}
          >
            Laporan Keuangan Tahunan
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 2 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent3)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent3)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent3)?.type == 'img' ? <PerCatImageItems data={dataContent3} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent3))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent3)).length > 1 && handleChangeNull(dataContent3)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent3)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 3 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 3 ? "true" : "false"}
            onClick={() => changeSelected(3)}
          // aria-controls={`collapse-${ca?.name}`}
          >
            Laporan Liquidity Coverage Ratio
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 3 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent4)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent4)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent4)?.type == 'img' ? <PerCatImageItems data={dataContent4} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent4))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent4)).length > 1 && handleChangeNull(dataContent4)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent4)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 4 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            aria-expanded={selected == 4 ? "true" : "false"}
            onClick={() => changeSelected(4)}
          >
            Laporan Net Stable Funding Ratio
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 4 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent5)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent5)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent5)?.type == 'img' ? <PerCatImageItems data={dataContent5} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent5))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent5)).length > 1 && handleChangeNull(dataContent5)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent5)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 5 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            aria-expanded={selected == 5 ? "true" : "false"}
            onClick={() => changeSelected(5)}
          >
            Informasi Kuantitatif Eksposur Risiko
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 5 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent6)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent5)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent6)?.type == 'img' ? <PerCatImageItems data={dataContent6} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent6))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent6)).length > 1 && handleChangeNull(dataContent6)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent6)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 6 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 6 ? "true" : "false"}
            onClick={() => changeSelected(6)}
          // aria-controls={`collapse-${ca?.name}`}
          >
            Laporan Leverage Ratio
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 6 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent7)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent7)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent7)?.type == 'img' ? <PerCatImageItems data={dataContent7} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent7))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent7)).length > 1 && handleChangeNull(dataContent2)?.type == 'pdf' && handleChangeNull(dataContent7)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent7)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 7 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 7 ? "true" : "false"}
            onClick={() => changeSelected(7)}
          // aria-controls={`collapse-${ca?.name}`}
          >
            Laporan Ukuran Utama
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 7 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent8)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent8)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent8)?.type == 'img' ? <PerCatImageItems data={dataContent8} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent8))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent8)).length > 1 && handleChangeNull(dataContent8)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent8)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
        <p className="mt-2">
          <button
            className={"btn border text-resp border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 8 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 8 ? "true" : "false"}
            onClick={() => changeSelected(8)}
          // aria-controls={`collapse-${ca?.name}`}
          >
            Laporan Suku Bunga Dasar Kredit
            <IoChevronDownCircle className="fs-2" />
          </button>
        </p>
        {selected == 8 && <div className='px-3'>
          <div className="h-100">
            {
              handleChangeNull(dataContent9)?.type === 'pdf' &&
              <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
            }
            <div className={`w-100 h-100 ${handleChangeNull(dataContent9)?.type === 'img' && ' mt-4'}`}>
              <div className="container">
                <div className="row">
                  {
                    handleChangeNull(dataContent9)?.type == 'img' ? <PerCatImageItems data={dataContent9} /> :
                      <PerCatItems startIndex={startIndex} data={handleData(handleChangeNull(dataContent9))} />
                  }
                </div>
              </div>
            </div>
          </div>
          {handleData(handleChangeNull(dataContent9)).length > 1 && handleChangeNull(dataContent9)?.type == 'pdf' &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ThemeProvider theme={themePagination}>
                <Pagination count={handleData(handleChangeNull(dataContent9)).length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
              </ThemeProvider>
            </div>
          }
        </div>
        }
      </div>
    </div >
  )
}

const PerCatItems = ({ startIndex, data }) => {
  return (
    data.length > 0 ?
      data[startIndex].map((c, k) => {
        const _split = c?.file?.split(".");
        const _ext = _split && _split.length > 0 ? _split[_split.length - 1] : null;
        return (
          <div key={k} className="col-12 col-lg-4 p-3">
            <div className="card d-flex flex-row justify-content-between align-items-center position-relative" style={{ minHeight: 100 }}>
              <div className="col-2 px-2 text-center d-flex justify-content-center align-items-center">
                {
                  ["png", "jpg", "jpeg"].includes(_ext)
                    ? <image src={c?.file} className='w-100' style={{ maxHeight: 100, objectFit: 'cover' }} />
                    : <BsFileEarmarkPdf className="text-primary" size={50} />
                }
              </div>
              <div className="col-10 card-body" style={{ alignSelf: 'stretch' }}>
                <h4 style={{ fontSize: 16 }} className='fw-bold'>{c?.name}</h4>
              </div>
              <div className="position-absolute end-0 bottom-0" style={{ cursor: "pointer" }} onClick={() => { window.open(c?.file, '_blank') }}>
                <BsDownload className='m-3 text-primary' size={24} />
              </div>
            </div>
          </div>
        )
      }
      )
      : <p style={{ textAlign: 'center' }}>Data tidak ditemukan</p>
  )
}

const PerCatImageItems = ({ data }) => {
  return data && data?.datas.map((c, k) => {
    return c.media?.preview && (
      <div key={k} className="mb-2">
        <img src={c.media.preview} alt={c.id} className='w-100' style={{ height: 'auto' }} />
      </div>
    )
  })
}

export default InformasiKeuangan