import React from 'react'
import { useTranslation } from 'react-i18next';
import { BsDownload } from 'react-icons/bs';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const PelaporanWhistleBlowingSystem = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/tata-kelola-perusahaan/pelaporan-whistle-blowing-system?lang=" + i18n.language);
  const { data: dataPengaduan } = useFetch("/tata-kelola-perusahaan/pelaporan-whistle-blowing-system/pengaduan?lang=" + i18n.language);
  const { data: dataPelaporan } = useFetch("/tata-kelola-perusahaan/pelaporan-whistle-blowing-system/penyampaian-pelaporan?lang=" + i18n.language);
  // console.log("page: ", dataPage)
  // console.log("dataPengaduan: ", dataPengaduan)
  // console.log("dataPelaporan", dataPelaporan)

  const metaValue = { title: dataPage ? '' + dataPage.title : 'Pelaporan Whistle Blowing System', description: dataPage ? limitText(dataPage.description, 255) : 'Pelaporan Whistle Blowing System', ogimage: dataPage ? dataPage.image1 : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container pt-5">
        <h1 className='fw-bold mb-3'>
          {dataPage?.title}
        </h1>
        <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.description }} />
        <div className="row">
          <div className="col-3">
            <img src={dataPage?.image1} className="w-100" alt="..." />
          </div>
          <div className="col-1"></div>
          <div className="col-8">
            <img src={dataPage?.image2} className="w-100" alt="..." />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <h3 className='text-tf-primary-7 fw-bold'>Penyampaian Pelaporan Whistle Blowing System</h3>
            <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.penyampaian }} />
          </div>
          <div className="col-12 col-lg-6">
            <table className='table mt-3'>
              <thead className='table-primary'>
                <tr>
                  <th className='text-light'>Tahun</th>
                  <th className='text-light'>Pengaduan</th>
                </tr>
              </thead>
              <tbody>
                {dataPengaduan && dataPengaduan.map((p, k) => {
                  return (<tr key={k}>
                    <td>  {p?.category} </td>
                    <td> <p dangerouslySetInnerHTML={{ __html: p?.description }}></p> </td>
                  </tr>)
                })}
              </tbody>
            </table>
            <a href={dataPage?.file1} target="_blank" rel='noreferrer' crossorigin className="fw-bold text-decoration-none" download><BsDownload size={18} className="me-2" /> {dataPage?.filename1}</a>
            <br />
            <a href={dataPage?.file2} target="_blank" rel='noreferrer' className="fw-bold text-decoration-none" download><BsDownload size={18} className="me-2" /> {dataPage?.filename2}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PelaporanWhistleBlowingSystem