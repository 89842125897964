import React from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import useWindowDimensions from "../../libs/useWindowDimensions";

const LightBoxVid = ({ show, vidUrl, closeVid }) => {
    const { height, width } = useWindowDimensions();
    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    // // console.log("Youtube : ", youtube_parser(vidUrl))
    // // console.log(vidUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/"))
    if (!show) {
        return <></>;
    }
    return (
        <div
            className="position-fixed vw-100 vh-100 bg-tf-dark d-flex justify-content-center align-items-center"
            style={{ zIndex: 999999999999999999 }}
        >
            <BsFillXCircleFill
                className="text-light position-fixed top-0 end-0 m-3"
                fontSize={32}
                onClick={closeVid}
            />
            <iframe
                width={width*0.8}
                height={(width*0.8)*4/5}
                src={`https://www.youtube.com/embed/${youtube_parser(vidUrl)}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default LightBoxVid;
