import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { Scrollbar } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
import useFetch from "../../../configs/services/useFetch";
// import '../../../../src/assets/styles/tentangKami.module.css'
import styles from '../../../../src/assets/styles/tentangKami.module.scss'
import '../../../../src/assets/styles/input.module.css';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";
import Meta from "../../Utils/Meta";
import { themePagination } from "../../../utils/themePagination";

const CSR = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [productPerView, setproductPerView] = useState(2);
  const nav = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const [searchByName, setSearchByName] = useState('')
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/about-us/csr/page?lang=" + i18n.language
  );
  // const { data: dataCategory } = useFetch("/about-us/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch("/about-us/csr?lang=" + i18n.language);
  const metaValue = { title: dataPage ? '' + dataPage.title : 'CSR BANK DKI', description: dataPage ? dataPage.description?.substr(0, 255) : 'CSR BANK DKI', ogimage: dataPage ? dataPage.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  let dataWithPagination = () => {
    let nestedArr = [];
    if (dataContent) {
      for (let i = 0; i < dataContent.filter((e) => e.title.toLowerCase().includes(searchByName.toLowerCase())).length; i += 9) {
        nestedArr.push(dataContent.filter((e) => e.title.toLowerCase().includes(searchByName.toLowerCase())).slice(i, i + 9));
      }
    }
    return nestedArr
  }

  let dataWithPagination_ = dataWithPagination()

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    // // console.log(productPerView);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setproductPerView(1);
    } else {
      setproductPerView(2);
    }
  }, [width]);
  return (
    <div>
      <Meta data={metaValue} />
      <div
        className="w-100 position-relative"
        style={{
          height: 400,
          overflow: "hidden",
          borderEndEndRadius: "25%",
          borderEndStartRadius: "25%",
          backgroundImage: `url(${dataPage?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="px-5 position-absolute top-0 left-0 w-100 h-100 text-white bg-tf-dark-35">
          <div className="container d-flex flex-column text-start justify-content-center h-100">
            <h1 className="fw-bold">{dataPage?.title}</h1>
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-2">
        <div className="row">
          <div className="col-12 col-lg-5 me-lg-5 mb-5">
            <p
              className="fs-6 text-light"
              dangerouslySetInnerHTML={{ __html: dataPage?.description }}
              style={{
                height: 300,
                overflow: "hidden",
                textAlign: "justify",
              }}
            ></p>
            <button
              className="btn btn-primary fw-bold fs-6 p-3 mt-3"
              data-bs-toggle="modal"
              data-bs-target="#selengkapnyaModal"
            >
              Selengkapnya
            </button>

            <div
              className="modal fade"
              id="selengkapnyaModal"
              tabindex="-1"
              aria-labelledby="selengkapnyaModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="selengkapnyaModalLabel">
                      {dataPage?.title}
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body fs-6 text-light px-5"
                    dangerouslySetInnerHTML={{ __html: dataPage?.description }}
                    style={{
                      textAlign: "justify",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-lg-1"></div> */}
          <div className="col-12 col-lg-6">
          </div>
        </div>
      </div>
      <div className="container my-5 text-center">
        <h4 className="fw-bold mb-5">Kegiatan-Kegiatan Sosial Bank DKI</h4>
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <input style={{ inlineSize: '300px', borderColor: '#D3D3D3', lineHeight: '27px', fontSize: 12 }} onChange={(e) => setSearchByName(e.target.value)} value={searchByName} placeholder="Search" className="my-5 rounded-2 px-2 soft-border" />
        </div>
        <div>
          <div className="col-12 col-lg-2 row w-100" >
            {dataContent &&
              dataContent.filter((e) => e.title.toLowerCase().includes(searchByName.toLowerCase())).slice(startIndex, (startIndex + 9)).map((artikel, k) => {
                return (
                  <div
                    key={k}
                    className={`position-relative rounded-3 testing ${styles.card}`}
                    style={{
                      height: 350,
                      margin: 2,
                      backgroundImage: `url(${artikel?.media?.original})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      nav(`${artikel.slug}`);
                    }}
                  >
                    <div className="position-absolute top-0 start-0 w-100 h-100 bg-gr-secondary-v rounded-3 d-flex align-items-end justify-content-center">
                      <h4 className="fw-bold text-white">{artikel?.title}</h4>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {dataWithPagination_.length > 1 &&
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 40 }}>
            <ThemeProvider theme={themePagination}>
              <Pagination count={dataWithPagination_.length} className="custom-pagination" color="primary" page={startIndex + 1} onChange={(e, v) => setStartIndex(v - 1)} shape="rounded" />
            </ThemeProvider>
          </div>
        }
      </div>
    </div>
  );
};

export default CSR;
