export const handleData = (d, searchByName) => {
  let _data = d
    ? d.datas.filter((e) =>
        e.name?.toLowerCase().includes(searchByName.toLowerCase())
      )
    : [];
  let container = [];
  let changed = [];

  const handlePushContainer = (e) => {
    container.push([...changed, e]);
    changed = [];
  };

  _data.forEach((e, i) => {
    (i + 1) % 9 === 0
      ? handlePushContainer(e)
      : _data.length === i + 1
      ? container.push([...changed, e])
      : changed.push(e);
  });
  return container;
};
