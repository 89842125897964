import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: "id",
        backend: {
            /* translation file path ada di folder public */
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        fallbackLng: "id",
        debug: false,
        /* dapat memiliki multiple namespace, siapa tau ingin membagi translation yang besar/kompleks ke bentuk yang lebih kecil kemudian load on demand */
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

export default i18n;
