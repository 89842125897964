import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import { IoChevronDownCircle } from "react-icons/io5";
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification'

const AspekTataKelolaPerusahaan = () => {
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { data: dataPage } = useFetch("/tata-kelola-perusahaan/aspek?lang=" + i18n.language);

  const changeSelected = (newSelected) => {
    if (newSelected == selected) {
      setSelected(null);
    } else {
      setSelected(newSelected);
    }
  }

  const metaValue = { title: dataPage ? dataPage.title : 'Aspek Tata Kelola Perusahaan', description: dataPage ? limitText(dataPage?.description, 255) : 'Aspek Tata Kelola Perusahaan', ogimage: dataPage ? dataPage.image1 : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div className='bg-white'>
      <Meta data={metaValue} />
      <div className="my-5">
        <img src={dataPage?.image2} alt="img-1" className='w-100' />

      </div>
      <div className="container">
        <div className="my-5">
          <div className="">
            <h1 className='fw-bold'>
              {dataPage?.title}
            </h1>
          </div>
          <div className="">
            <p style={{ textAlign: 'justify' }} className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.description }}></p>
          </div>
        </div>
        <div className="">
          <div className="">
            <img src={dataPage?.image1} alt="img-1" className='w-100' />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 0 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 0 ? "true" : "false"}
            onClick={() => changeSelected(0)}
          >
            Struktur
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 0 && <div className='px-3'>
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Pedoman Dan Kebijakan Tata Kelola Perusahaan</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.struktur }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>}
        </p>
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 1 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 1 ? "true" : "false"}
            onClick={() => changeSelected(1)}
          >
            Mekanisme
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 1 && <div className='px-3'>
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Mekanisme Tata Kelola Perusahaan</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.mekanisme }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>}
        </p>
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 2 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${ca?.name}`}
            aria-expanded={selected == 2 ? "true" : "false"}
            onClick={() => changeSelected(2)}
          >
            Soft-Struktur
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 2 && <div className='px-3'>
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Struktur Tata Kelola Perusahaan</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.softstruktur }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>}
        </p>
      </div>
    </div>
  )
}

export default AspekTataKelolaPerusahaan