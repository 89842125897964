import React from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import Meta from "../../Utils/Meta";

const IdentitasPerusahaan = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/profile/company-identity/page?lang=" + i18n.language
  );
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch(
    "/profile/company-identity?lang=" + i18n.language
  );

  const metaValue = {
    title: 'Identitas perusahaan', description: dataPage ? dataPage?.description?.substr(0, 255) : 'ini deskripsi identitas perusahaan', ogimage: dataPage ? dataPage?.image1 : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg'
  }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="mt-5">
        <img src={dataPage?.image1} className="w-100" alt="img/1" />
      </div>
      <div className="container mt-5">
        <div className="mb-5">
          <div className="">
            <h1 className="fw-bold">{dataPage?.title}</h1>
            <p
              className="text-light mt-4 fs-6"
              style={{
                textAlign: "justify",
              }}
            >
              {dataPage?.description}
            </p>
          </div>
        </div>
        <div className="row text-center mb-5">
          <div className="col">
            <img src={dataPage?.image2} className="w-75" alt="img/1" />
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          {dataContent &&
            dataContent.map((konten, k) => {
              return (
                <div key={k} className="col-12 col-md-6 col-lg-4 p-3">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <h4 style={{ minHeight: 80 }} className="fw-bold mb-4">{konten?.title}</h4>
                      <p
                        className="text-light fs-6"
                        dangerouslySetInnerHTML={{
                          __html: konten?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default IdentitasPerusahaan;
