import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const HubunganAfiliasi = () => {
  const { i18n } = useTranslation();
  const { data: dataContent } = useFetch("/tata-kelola-perusahaan/hubungan-afiliasi?lang=" + i18n.language);

  const metaValue = { title: dataContent ? '' + dataContent.title : 'Hubungan Afiliasi', description: dataContent ? limitText(dataContent.description, 255) : 'Hubungan Afiliasi', ogimage: dataContent ? dataContent.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <img src={dataContent?.image} className="w-100 mb-3" alt="...." />
        <div className="row">
          <div className="col-12 col-lg-4">
            <h1 className='fw-bold'>
              {dataContent?.title}
            </h1>
          </div>
          <div className="col-12 col-lg-8">
            <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataContent?.description }} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex">
          <div className="col-12 col-md-6 col-lg-4 p-3">
            <div className="card" style={{ height: 150 }}>
              <div className="card-body">
                <div className="row h-100">
                  <div className="col-4 px-2 text-center">
                    {/* <img src={dataContent?.image} alt="asd" className='w-100' /> */}
                    <BsFileEarmarkSpreadsheetFill className="text-primary" size={78} />
                  </div>
                  <div className="col-8 position-relative">
                    <h4 style={{ fontSize: 20 }} className='fw-bold mb-4'>{dataContent?.name1}</h4>
                    <a href={dataContent?.file1} target="_blank" rel='noreferrer' className="fw-thin position-absolute start-0 bottom-0 mx-3">View Offer</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 p-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="row h-100">
                  <div className="col-4 px-2 text-center">
                    {/* <img src={dataContent?.image} alt="asd" className='w-100' /> */}
                    <BsFileEarmarkSpreadsheetFill className="text-primary" size={78} />
                  </div>
                  <div className="col-8 position-relative">
                    <h4 style={{ fontSize: 20 }} className='fw-bold mb-4'>{dataContent?.name2}</h4>
                    <a href={dataContent?.file2} target="_blank" rel='noreferrer' className="fw-thin position-absolute start-0 bottom-0 mx-3">View Offer</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HubunganAfiliasi