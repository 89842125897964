import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import useFetch from "../../configs/services/useFetch";

const FormKMG = () => {
  const [Nasabah, setNasabah] = useState(0.5); //dsr
  const [Plafon, setPlafon] = useState(0);
  const [Tenor, setTenor] = useState(0);
  const [Angsuran, setAngsuran] = useState(false);
  const [MinPenghasilan, setMinPenghasilan] = useState(null);
  const [sukuBunga, setSukuBunga] = useState(0)
  let estimasiAngsuranPerBulan = Math.round(
    (Plafon * (1 + ((sukuBunga / 100) / 12) * (Tenor * 12))) / (Tenor * 12)
  )
  const [result, setResult] = useState({ angsuran: '', penghasilan: '' })

  const { i18n } = useTranslation();
  const { data: dataNasabah } = useFetch(
    "/calculator/jenis-nasabah?lang=" + i18n.language
  );
  // const { data: Bunga } = useFetch("/calculator/kpr-kmg?type=kmg");

  useEffect(() => {
    if (dataNasabah) {
      setNasabah(dataNasabah[0].dsr);
    }
  }, [dataNasabah]);

  const resetForm = (e) => {
    e.preventDefault();
    if (dataNasabah) {
      setNasabah(dataNasabah[0].dsr);
    }
    setPlafon(0);
    setSukuBunga(0)
    setTenor(0);
    setAngsuran(null);
    setMinPenghasilan(null);
    window.scrollTo(0, 0)
  };

  const hasilHitung = (e) => {
    window.scrollTo(0, 500)
    e.preventDefault()
    setAngsuran(true)
    setResult({ angsuran: estimasiAngsuranPerBulan, penghasilan: Number(Math.round(Math.ceil(estimasiAngsuranPerBulan / 0.5 * Math.pow(10, -5)) / Math.pow(10, -5)).toFixed(2)) })
    // let SukuBunga = Bunga / 100;

    // var hitungAngsuran = Math.round(
    //   (Plafon * 0.5 * (1 + (sukuBunga / 12) * (Tenor * 12))) / (Tenor * 12)
    // );
    // setAngsuran(hitungAngsuran);
    // setMinPenghasilan(hitungMinimalPenghasilan);
    // console.log("plafon", Plafon);
    // console.log("Jangka waktu", Tenor);
    // console.log("Suku Bunga", Bunga);
    // console.log("hitungAngsuran : ", hitungAngsuran);
    // console.log("hitungMinimalPenghasilan : ", hitungMinimalPenghasilan);
  };

  const handleMaxPercent = (e) => {
    if (Number(e) <= 100) {
      setSukuBunga(e);
    }
  }

  return (
    <>
      <form>
        {/* <div className="mb-4 pt-2">
          <label
            htmlFor="jenis-nasabah"
            className="form-label fw-bold text-light mb-0"
            style={{ fontSize: 14 }}
          >
            Jenis Nasabah
          </label>
          <select
            name="jenis-nasabah"
            className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
            style={{ fontSize: 14 }}
            id="jenis-nasabah"
            value={Nasabah}
            onChange={(e) => setNasabah(e.target.value)}
          >
            {dataNasabah &&
              dataNasabah.map((d, k) => {
                return (
                  <option key={k} value={d.dsr}>
                    {d.name}
                  </option>
                );
              })}
          </select>
        </div> */}
        <div className="my-4 pt-2">
          <label
            htmlFor="plafon"
            className="form-label fw-bold text-light mb-0"
            style={{ fontSize: 14 }}
          >
            Plafon Kredit KMG
          </label>
          <NumericFormat
            displayType="input"
            thousandSeparator={"."}
            prefix={"Rp "}
            allowNegative={false}
            decimalSeparator=","
            name="plafon"
            className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
            style={{ fontSize: 14 }}
            id="plafon"
            value={Plafon}
            onValueChange={(e) => setPlafon(e.floatValue)}
          />
        </div>
        <div className="mb-4 pt-2">
          <label
            htmlFor="tenor"
            className="form-label fw-bold text-light mb-0"
            style={{ fontSize: 14 }}
          >
            Jangka Waktu
          </label>
          <div className="input-group">
            {/* <input
            type="number"
            name="tenor"
            className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
            style={{ fontSize: 14 }}
            id="tenor"
            value={Tenor}
            onChange={(e) => setTenor(e.target.value)}
            /> */}
            <NumericFormat
              displayType="input"
              id="tenor"
              name="tenor"
              thousandSeparator={"."}
              decimalSeparator=","
              className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
              style={{ fontSize: 14 }}
              value={Tenor}
              allowNegative={false}
              onValueChange={(e) => setTenor(e.floatValue)}
              pattern="[0-9]+"
              onKeyDown={(e) => {
                // Mencegah inputan angka negatif dan koma
                if (e.key === '-' || e.key === '.') {
                  e.preventDefault();
                }
              }}
            />
            <span
              className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
              id="basic-addon1"
            >
              tahun
            </span>
          </div>
        </div>

        <div className="mb-4 pt-2 row">
          <div className="col">
            <label
              htmlFor="bunga"
              className="form-label fw-bold text-light mb-0"
              style={{ fontSize: 14 }}
            >
              Suku Bunga
            </label>
            <div className="input-group">
              <NumericFormat
                name="bunga"
                displayType="input"
                thousandSeparator={"."}
                decimalScale={2}
                allowNegative={false}
                decimalSeparator=","
                className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
                style={{ fontSize: 14 }}
                id="bunga"
                value={sukuBunga}
                aria-describedby="group-bunga"
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                onValueChange={(e) => handleMaxPercent(e.floatValue ?? 0)}
              />


              <span
                className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
                id="group-bunga"
              >
                %
              </span>
            </div>
          </div>
          <div className="col d-flex align-items-end">
            <div className="mx-auto">
              <button
                type="input"
                className='btn btn-danger fs-6 px-4 py-2 fw-bold me-2 rounded-3'
                onClick={hasilHitung}
                style={{ cursor: 'pointer' }}
                disabled={!Plafon || !Tenor || !sukuBunga}
              >
                Hitung
              </button>

              <button
                type="reset"
                className="btn btn-outline-danger fs-6 px-4 py-2 fw-bold ms-2 rounded-4"
                onClick={resetForm}
                disabled={!Angsuran}
              >
                Atur Ulang
              </button>
            </div>
          </div>
        </div>
      </form>
      {Angsuran ? (
        <>
          <h4 className="fw-bold mt-4">Hasil Kalkulasi</h4>
          <div className="fw-bold text-light my-4 px-4 border-light" style={{ fontSize: 14, borderStyle: 'solid', padding: '20px 5px', borderWidth: '1px', borderRadius: '0.25rem' }}>
            {/* <div className="row">
              <div className="col text-danger">Estimasi Angsuran Perbulan</div>
              <div className="col text-danger">Rp {estimasiAngsuranPerBulan.toLocaleString("id-ID")}</div>
            </div>
            <div className="row">
              <div className="col text-danger">Estimasi Minimal Penghasilan</div>
              <div className="col text-danger">
                Rp {Math.round(Math.ceil(estimasiAngsuranPerBulan / 0.5 * Math.pow(10, -5)) / Math.pow(10, -5)).toLocaleString("id-ID")}
              </div>
            </div> */}

            <div className="text-danger mb-2" style={{ opacity: 0.5 }}>Estimasi Angsuran Perbulan</div>
            <div className="text-danger" style={{ fontSize: '24px' }}>Rp {Number(Number(result.angsuran).toFixed(2)).toLocaleString("id-ID")}</div>
            {/* <div className="text-danger" style={{ fontSize: '24px' }}>Rp {Number(estimasiAngsuranPerBulan.toFixed(2)).toLocaleString("id-ID")}</div> */}
            <div className="mt-4 text-danger mb-2" style={{ opacity: 0.5 }}>Estimasi Penghasilan</div>
            <p className="text-danger" style={{ fontSize: '24px' }}>
              Rp {result.penghasilan.toLocaleString("id-ID")}
            </p>
            {/* <div className="text-end">
              <button
                type="input"
                className="btn btn-danger px-4 py-2 fw-bold me-2 rounded-4"
                onClick={resetForm}
                style={{ fontSize: '14px' }}
              >
                Hitung Ulang
              </button>
            </div> */}
          </div>
        </>
      ) :
        <></>
      }
      <p className="text-light"><b>Syarat dan Ketentuan</b></p>
      <ul style={{ paddingLeft: 18 }}>
        {['Perincian Kredit di atas hanya merupakan simulasi / estimasi biaya.', 'Perhitungan tersebut masih belum termasuk biaya lain-lain.', 'Perhitungan di atas bersifat tidak mengikat.', 'Informasi Lanjut, silahkan hubungi Call Center / Kantor Cabang Bank DKI terdekat.'].map(e => (
          <li className="text-light">{e}</li>
        ))}
      </ul>
    </>
  );
};

export default FormKMG;
    // <form>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="jenis-nasabah"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Jenis Nasabah
    //     </label>
    //     <select
    //       name="jenis-nasabah"
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="jenis-nasabah"
    //       value={Nasabah}
    //       onChange={(e) => setNasabah(e.target.value)}
    //     >
    //       {dataNasabah &&
    //         dataNasabah.map((d, k) => {
    //           return (
    //             <option key={k} value={d.dsr}>
    //               {d.name}
    //             </option>
    //           );
    //         })}
    //     </select>
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="plafon"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Plafon Kredit KMG
    //     </label>
    //     <NumericFormat
    //       displayType="input"
    //       thousandSeparator={"."}
    //       prefix={"Rp "}
    //       decimalSeparator=","
    //       name="plafon"
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="plafon"
    //       value={Plafon}
    //       onValueChange={(e) => setPlafon(e.floatValue)}
    //     />
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="bunga"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Suku Bunga
    //     </label>
    //     <div className="input-group">
    //       {/* <input
    //         type="number"
    //         name="bunga"
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="bunga"
    //         value={Bunga}
    //         aria-describedby="group-bunga"
    //         disabled
    //         // onChange={(e) => setBunga(e.target.value)}
    //       /> */}
    //       <NumericFormat
    //         name="bunga"
    //         displayType="input"
    //         thousandSeparator={"."}
    //         decimalSeparator=","
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="bunga"
    //         value={sukuBunga}
    //         aria-describedby="group-bunga"
    //         isAllowed={(values) => {
    //           const { formattedValue, floatValue } = values;
    //           return formattedValue === "" || floatValue <= 100;
    //         }}
    //         onValueChange={(e) => handleMaxPercent(e.floatValue ?? 0)}
    //       />


    //       <span
    //         className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
    //         id="group-bunga"
    //       >
    //         %
    //       </span>
    //     </div>
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="tenor"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Jangka Waktu
    //     </label>
    //     <div className="input-group">
    //       {/* <input
    //         type="number"
    //         name="tenor"
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="tenor"
    //         value={Tenor}
    //         onChange={(e) => setTenor(e.target.value)}
    //         /> */}
    //       <NumericFormat
    //         displayType="input"
    //         id="tenor"
    //         name="tenor"
    //         thousandSeparator={"."}
    //         decimalSeparator=","
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         value={Tenor}
    //         onValueChange={(e) => setTenor(e.floatValue)}
    //       />
    //       <span
    //         className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
    //         id="basic-addon1"
    //       >
    //         /tahun
    //       </span>
    //     </div>
    //   </div>

    //   {Angsuran ? (
    //     <>
    //       <h4 className="fw-bold">Hasil Kalkulasi</h4>
    //       <div className="fw-bold text-light mb-4" style={{ fontSize: 14 }}>
    //         <div className="row">
    //           <div className="col">Plafon Kredit</div>
    //           <div className="col">Rp {Plafon?.toLocaleString("EN")}</div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Jangka Waktu Kredit</div>
    //           <div className="col">{Tenor} Tahun</div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Bunga/Tahun (%)</div>
    //           <div className="col">
    //             {Bunga}% ({(Plafon * (Bunga / 100)).toLocaleString("EN")})
    //           </div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Estimasi Angsuran Perbulan</div>
    //           <div className="col">Rp {Angsuran.toLocaleString("EN")}</div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Estimasi Minimal Penghasilan</div>
    //           <div className="col">
    //             Rp {MinPenghasilan.toLocaleString("EN")}
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ) : null}

    //   <div className="text-end mb-4">
    //     <button
    //       type="input"
    //       className="btn btn-danger fs-6 px-4 py-2 fw-bold me-2 rounded-4"
    //       onClick={hasilHitung}
    //     >
    //       Hitung
    //     </button>
    //     <button
    //       type="reset"
    //       className="btn btn-outline-danger fs-6 px-4 py-2 fw-bold ms-2 rounded-4"
    //       onClick={resetForm}
    //     >
    //       Reset
    //     </button>
    //   </div>
    // </form>
