import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
// import useFetch from '../../configs/services/useFetch';
import { BiSearchAlt } from "react-icons/bi";
import Meta from '../Utils/Meta';
import SearchLoading from '../../components/Search';
import axios from "axios";

const Search = () => {
    const navigate = useNavigate();
    // const { i18n } = useTranslation();
    const [menu, setMenu] = useState("")
    const search = useLocation().search;
    const keyword = new URLSearchParams(search).get('keyword');
    // const { data: dataContent } = useFetch("/misc/search?menu=" + Menu + "&category=&search=" + keyword + "&limit=100&page=1");
    const [searchValue, setSearchValue] = useState(new URLSearchParams(search).get('keyword'));
    const metaValue = { title: 'Search', description: 'Search', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
    const [data1, setData1] = useState({ success: false, message: 'loading', data: { datas: [] } });
    const [data2, setData2] = useState({ success: false, message: 'loading', data: { datas: [] } });
    const [data3, setData3] = useState({ success: false, message: 'loading', data: { datas: [] } });
    const [data4, setData4] = useState({ success: false, message: 'loading', data: { datas: [] } });


    useEffect(() => {
        setSearchValue(new URLSearchParams(search).get('keyword'))
    }, [new URLSearchParams(search).get('keyword')])

    const handleSelectMenu = () => {
        switch (menu) {
            case "digital-banking": return data1; break;
            case "product": return data2; break;
            case "promo": return data3; break;
            case "article": return data4; break;
            default: return data1; break;
        }
    }
    async function handleAutoSelectMenu() {
        const env = process.env.REACT_APP_API
        const handleFetchData = async (_menu) => await axios.get(env + "api/v1/misc/search?menu=" + _menu + "&category=&search=" + keyword + "&limit=100&page=1")

        let order = 1
        try {
            const notEmpty = []
            const response1 = await handleFetchData('digital-banking')
            let changeRes1 = { ...response1.data }
            changeRes1.loading = false
            setData1(changeRes1)
            if (response1.data.data.datas.length) notEmpty.push('digital-banking')
            order = 2

            const response2 = await handleFetchData('product')
            let changeRes2 = { ...response2.data }
            changeRes2.loading = false
            order = 3
            setData2(changeRes2)
            if (response2.data.data.datas.length) notEmpty.push('product')

            const response3 = await handleFetchData('promo')
            let changeRes3 = { ...response3.data }
            changeRes3.loading = false
            order = 4
            setData3(changeRes3)
            if (response3.data.data.datas.length) notEmpty.push('promo')

            const response4 = await handleFetchData('article')
            let changeRes4 = { ...response4.data }
            changeRes4.loading = false; changeRes4.order = 4;
            order = 0
            setData4(changeRes4)
            if (response4.data.data.datas.length) notEmpty.push('article')

            notEmpty.length > 0 && setMenu(notEmpty[0])
        } catch (error) {
            if (order > 0) {
                if (order === 1) {
                    setData1({ success: false, message: 'error' })
                    setData2({ success: false, message: 'error' })
                    setData3({ success: false, message: 'error' })
                    setData4({ success: false, message: 'error' })
                }
                else if (order === 2) {
                    setData2({ success: false, message: 'error' })
                    setData3({ success: false, message: 'error' })
                    setData4({ success: false, message: 'error' })
                }
                else if (order === 3) {
                    setData3({ success: false, message: 'error' })
                    setData4({ success: false, message: 'error' })
                } else {
                    setData4({ success: false, message: 'error' })
                }
            }
        }
    }

    useEffect(() => {
        handleAutoSelectMenu();
    }, [new URLSearchParams(search).get('keyword')]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        navigate(`/search?keyword=${searchValue}`)
    };

    const handleInputChange = (e) => {
        setSearchValue(e);
    };
    return (
        <div style={{ marginTop: 100 }}>
            <Meta data={metaValue} />
            <div className="container">
                <div className="row my-3">
                    <div className="col-12 col-lg-2"></div>
                    <div className="col-12 col-lg-8 p-3">
                        <form
                            onSubmit={handleFormSubmit}
                        >
                            <div className="input-group mb-3">
                                <input type="search" value={searchValue} onChange={e => handleInputChange(e.target.value)} className="form-control border-end-0 py-2 fw-bold"
                                    style={{
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderTopLeftRadius: 20,
                                        borderBottomLeftRadius: 20
                                    }} placeholder="Search here ..." aria-label="Search here ..." aria-describedby="search" />
                                <button className="input-group-text px-5 bg-white border-start-0"
                                    style={{
                                        borderTopRightRadius: 20,
                                        borderBottomRightRadius: 20,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0
                                    }} id="search"><BiSearchAlt /> </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-lg-2"></div>
                </div>
                <div className="row my-3">
                    <div className="col-12 col-lg-2 py-3">
                        <div className="card w-100 shadow-lg border-0">
                            <div className="card-body">
                                <button className={menu === "digital-banking" ? 'd-flex btn w-100 border-0 fw-bold text-black my-2' : 'd-flex btn w-100 border-0 fw-bold text-light my-2'} onClick={() => setMenu("digital-banking")}>Digital&nbsp;{data1.data.datas.length > 0 ? <div className="rounded-circle count-search">{data1.data.datas.length}</div> : <></>}</button>
                                <button className={menu === "product" ? 'd-flex btn w-100 border-0 fw-bold text-black my-2' : 'd-flex btn w-100 border-0 fw-bold text-light my-2'} onClick={() => setMenu("product")}>Produk&nbsp;{data2.data.datas.length > 0 ? <div className="rounded-circle count-search">{data2.data.datas.length}</div> : <></>}</button>
                                <button className={menu === "promo" ? 'd-flex btn w-100 border-0 fw-bold text-black my-2' : 'd-flex btn w-100 border-0 fw-bold text-light my-2'} onClick={() => setMenu("promo")}>Promo&nbsp;{data3.data.datas.length > 0 ? <div className="rounded-circle count-search">{data3.data.datas.length}</div> : <></>}</button>
                                <button className={menu === "article" ? 'd-flex btn w-100 border-0 fw-bold text-black my-2' : 'd-flex btn w-100 border-0 fw-bold text-light my-2'} onClick={() => setMenu("article")}>Artikel&nbsp;{data4.data.datas.length > 0 ? <div className="rounded-circle count-search">{data4.data.datas.length}</div> : <></>}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 p-3">
                        <div className="card w-100 shadow-lg border-0">
                            <div className="card-body">
                                <div className="row">
                                    {
                                        handleSelectMenu().success && handleSelectMenu().message.toLowerCase() === 'success' ? handleSelectMenu().data.datas.map((c, k) => {
                                            return (<div key={k} className="col-12 col-lg-4 my-2">
                                                <div className="card w-100 h-100" style={{ cursor: "pointer" }} onClick={() => {
                                                    menu === "digital-banking" ? navigate(`/digital/${c?.slug}`) : menu === "product" ? navigate(`/produk/${c?.slug}`) : menu === "promo" ? navigate(`/promo/${c?.slug}`) : navigate(`/artikel/${c?.slug}`)
                                                }}>
                                                    <img src={c?.media?.preview} alt="..." className='rounded-top' />
                                                    <div className="card-body">
                                                        <span className='fw-bold text-primary'>
                                                            {c?.title}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>)
                                        })
                                            :
                                            handleSelectMenu().message.toLowerCase() == 'loading' && !handleSelectMenu().success ?
                                                <SearchLoading />
                                                : <p>Gagal Mendapatkan Data</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2"></div>
                </div>
            </div>
        </div >
    )
}

export default Search