import React from "react";
import { BsChatRightQuoteFill, BsFillCalculatorFill } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { Link } from "react-router-dom";

const FloatNav = () => {
    return (
        <div className="float-nav" style={{zIndex: 999}}>
            <div className="float-nav-menu">
                <Link
                    to="/jaringan-layanan"
                    className="pt-3 menu-item rounded-top text-decoration-none"
                >
                    <SiGooglemaps fontSize={28} />
                    <p className="fw-bold short">Lokasi</p>
                    <p className="fw-bold long">Jaringan Layanan</p>
                </Link>
            </div>
            <div className="float-nav-menu">
                <Link
                    to="/bantuan"
                    className="pt-3 menu-item rounded-start text-decoration-none"
                >
                    <BsChatRightQuoteFill fontSize={28} />
                    <p className="fw-bold short">Bantuan</p>
                    <p className="fw-bold long">Bantuan</p>
                </Link>
            </div>
            <div className="float-nav-menu">
                <Link
                    to="/kalkulator-finansial/deposito"
                    className="pt-3 menu-item rounded-bottom text-decoration-none"
                >
                    <BsFillCalculatorFill fontSize={28} />
                    <p className="fw-bold short">Kalkulator</p>
                    <p className="fw-bold long">Kalkulator Finansial</p>
                </Link>
            </div>
        </div>
    );
};

export default FloatNav;
