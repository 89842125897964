import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import { useNavigate } from "react-router-dom";
import Meta from "../../Utils/Meta";

const SiaranPers = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [tahun, setTahun] = useState(0);
  const { data: dataCategory } = useFetch(
    "/hubungan-investor/siaran-pers/category"
  );
  const { data: dataContent } = useFetch(
    "/hubungan-investor/siaran-pers?lang=" +
    i18n.language +
    "&page=1&limit=0&year=" +
    tahun +
    ""
  );
  // console.log("dataCategory", dataCategory);
  // console.log("dataContent", dataContent);

  useEffect(() => {
    if (dataCategory) {
      setTahun(dataCategory[0]?.name);
    }
  }, [dataCategory]);

  const metaValue = { title: 'Siaran Pers', description: 'Siaran Pers', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="">
          <h1 className="fw-bold mb-3">Siaran Pers</h1>
          {dataCategory &&
            dataCategory.map((c, k) => (
              <button
                key={k}
                onClick={() => setTahun(c?.name)}
                className={
                  c?.name === tahun
                    ? "btn fw-bold btn-secondary px-4 py-2 mx-1"
                    : "btn fw-bold text-secondary bg-tf-primary px-4 py-2 mx-1"
                }
              >
                {c?.name}
              </button>
            ))}
        </div>
        <div className="my-3">
          <div className="">
            <div className="card">
              <div className="card-body">
                {dataContent &&
                  dataContent?.datas.map((c, k) => {
                    return (
                      <div key={k} className="d-flex justify-content-between align-items-start my-2 py-2" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="fw-bold" style={{ width: 175 }}>{c?.created_at}</div>
                        <div className="w-100 px-3 fw-bold">{c?.title}</div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary"
                            onClick={() => navigate(`/siaran-pers/${c?.slug}`)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiaranPers;
