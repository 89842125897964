import React from "react";
import ImgOjk from "../../assets/img/ojk.png";
import ImgLps from "../../assets/img/lps.png";
import BankDKILogo from "../../assets/img/logo.svg";
import useFetch from "../../configs/services/useFetch";
import ImgCallcen from "../../assets/img/callcenter.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { i18n } = useTranslation();
  const { data: dataAlamatUtama } = useFetch("/footer/alamat-utama");
  const { data: dataCallUs } = useFetch("/footer/call-us");
  const { data: dataSosmed } = useFetch("/footer/social-media");
  const { data: dataMenuDigital } = useFetch(
    "/digital-banking?lang=" + i18n.language
  );
  const { data: productCategory } = useFetch(
    "/product-service/category?lang=" + i18n.language
  );

  const { pathname } = useLocation()

  // console.log("nav", pathname);
  // // console.log(dataCallUs)
  return (
    <div className="footer w-100">
      <div className="f-1 bg-footer-1 w-100 pb-3 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <img src={BankDKILogo} className="w-75 py-3" alt="..." />
              <p
                className="text-light"
                dangerouslySetInnerHTML={{
                  __html: dataAlamatUtama ? dataAlamatUtama : "",
                }}
                style={{ fontSize: 14 }}
              ></p>
              <h6 className="fw-bold text-dark text-decoration-underline">
                Media Sosial
              </h6>
              <div className="text-light mb-4" style={{ fontSize: 20 }}>
                {dataSosmed &&
                  dataSosmed.map((sosmed, k) => {
                    return (
                      <a
                        key={k}
                        className="me-3"
                        target="_blank"
                        rel="noreferrer"
                        href={sosmed?.link}
                      >
                        <img
                          src={sosmed?.icon}
                          className="rounded-circle"
                          alt={sosmed?.title}
                          width={32}
                          height={32}
                        />
                      </a>
                    );
                  })}
              </div>
              <h6 className="fw-bold text-dark text-decoration-underline">
                Hubungi Kami
              </h6>
              <div className="row mb-3">
                <div className="col-3">
                  <img src={ImgCallcen} alt="call-center" className="w-100" />
                </div>
                <div className="col-9">
                  <div className="row text-light" style={{ fontSize: 12 }}>
                    <div className="col-4">Email</div>
                    <div className="col-8">: {dataCallUs?.email}</div>
                    <div className="col-4">Telepon</div>
                    <div className="col-8">: {dataCallUs?.telepon}</div>
                    <div className="col-4">Faksimili</div>
                    <div className="col-8">: {dataCallUs?.faksmil}</div>
                    <div className="col-4">Call Center</div>
                    <div className="col-8">: {dataCallUs?.callcenter}</div>
                    <div className="col-12"><a className="text-light" style={{ textDecoration: 'none' }} href="https://www1.bankdki.co.id" target="_blank">Website versi sebelumnya</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <h5 className="fw-bold">Digital</h5>
                      <div className="text-dark" style={{ fontSize: 12 }}>
                        <div className="mb-1">
                          <Link
                            className={pathname === "/digital/jak-one-mobile" ? "text-danger fw-bold" : "text-dark text-decoration-none"}
                            to={"/digital/jak-one-mobile"}
                          >
                            JakOne Mobile
                          </Link>
                        </div>
                        {dataMenuDigital &&
                          dataMenuDigital.map((m, k) => {
                            if (k < 3) {
                              return (
                                <div key={k} className="mb-1">
                                  <Link
                                    className={pathname === `/digital/${m.slug}` ? "text-danger fw-bold" : "text-dark text-decoration-none"}
                                    to={`/digital/${m.slug}`}
                                  >
                                    {m.title}
                                  </Link>
                                </div>
                              );
                            }
                            return null;
                          })}
                        <div>
                          <Link
                            className={pathname === "/digital" ? "text-danger fw-bold" : "text-dark text-decoration-none"}
                            to={"/digital"}
                          >
                            Lihat Semua Digital Banking
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <h5 className="fw-bold">Karir</h5>
                      <div className="text-dark" style={{ fontSize: 12 }}>
                        <Link
                          className={pathname === "/karir" ? "text-danger fw-bold" : "text-dark text-decoration-none"}
                          to={"/karir"}
                        >
                          Lihat semua lowongan
                        </Link>
                        <br />
                      </div>
                    </div>

                  </div>
                  <div className="col">
                    <h5 className="fw-bold">Produk</h5>
                    <div style={{ fontSize: 12 }}>
                      {productCategory &&
                        productCategory.map((pc, k) => {
                          return (
                            <div key={k} className="mb-1">
                              <Link
                                className={pathname === `/produk/${pc?.id}/${pc?.name.replace(/ /g, "%20")}` ? "text-danger fw-bold" : "text-dark text-decoration-none"}
                                to={`produk/${pc?.id}/${pc?.name}`}
                              >
                                {pc?.name}
                              </Link>
                            </div>
                          );
                        })}
                      <br />
                    </div>
                  </div>
                  <div className="col">
                    <h5 className="fw-bold">Promo</h5>
                    <div className="text-dark" style={{ fontSize: 12 }}>
                      <Link className={pathname === "/promo" ? "text-danger fw-bold" : "text-dark text-decoration-none"} to={"/promo"}>
                        Lihat Semua Promo
                      </Link>
                      <br />
                    </div>
                  </div>
                  <div className="col">
                    <h5 className="fw-bold">Tentang Kami</h5>
                    <div className="text-dark d-flex flex-column" style={{ fontSize: 12 }}>
                      <Link
                        className={pathname === "/tentang-kami/profil-bank-dki/sejarah-profil-bank-dki" ? "text-dark mb-1 fw-bold" : "text-dark text-decoration-none mb-1"}
                        to={"/tentang-kami/profil-bank-dki/sejarah-profil-bank-dki"}
                      >
                        Profil Bank DKI
                      </Link>

                      <Link
                        className={pathname === "/tentang-kami/management-bank-dki/dewan-komisaris" ? "text-dark mb-1 fw-bold" : "text-dark text-decoration-none mb-1"}
                        to={"/tentang-kami/management-bank-dki/dewan-komisaris"}
                      >
                        Management Bank DKI
                      </Link>

                      <Link
                        className={pathname === "/tentang-kami/tata-kelola-perusahaan/aspek-tata-kelola-perusahaan" ? "text-dark mb-1 fw-bold" : "text-dark text-decoration-none mb-1"}
                        to={
                          "/tentang-kami/tata-kelola-perusahaan/aspek-tata-kelola-perusahaan"
                        }
                      >
                        Tata Kelola Perusahaan
                      </Link>

                      <Link
                        className={pathname === "/tentang-kami/hubungan-investor/informasi-keuangan" ? "text-dark mb-1 fw-bold" : "text-dark text-decoration-none mb-1"}
                        to={"/tentang-kami/hubungan-investor/informasi-keuangan"}
                      >
                        Hubungan Investor
                      </Link>

                      <Link
                        className={pathname === "/tentang-kami/csr" ? "text-dark mb-1 fw-bold" : "text-dark text-decoration-none mb-1"}
                        to={"/tentang-kami/csr"}
                      >
                        CSR
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="f-2 bg-footer-2 w-100 p-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="mitra">
                {/* <img src={ImgOjk} className="w-25 me-2" alt="..." /> */}
                <img src={ImgLps} className="w-25 me-2" alt="..." />
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div
                className="h-100 d-flex align-items-center justify-content-end text-center"
                style={{ fontSize: 14 }}
              >
                © 2022 - PT Bank DKI adalah pelaku jasa keuangan yang berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK), dan merupakan peserta program Penjaminan Lembaga Penjamin Simpanan (LPS).
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
