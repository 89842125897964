import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import { BsDownload, BsFileEarmarkPdf } from "react-icons/bs";
import Meta from "../../Utils/Meta";

const LaporanTataKelolaPerusahaan = () => {
  const { i18n } = useTranslation();
  const [tahun, setTahun] = useState("");
  const { data: dataContent } = useFetch(
    "/tata-kelola-perusahaan/laporan?lang=" + i18n.language + "&year=" + tahun
  );
  const { data: dataYear } = useFetch(
    "/tata-kelola-perusahaan/laporan/category"
  );
  // console.log('dataYear', dataYear);

  const metaValue = { title: 'Laporan Pelaksanaan Tata Kelola Perusahaan', description: 'Tata Kelola yang baik adalah suatu tata cara pengelolaan Bank yang menerapkan prinsip-prinsip keterbukaan (transparency), akuntabilitas (accountability), pertanggungjawaban (responsibilty), dan kewajaran (fairness)', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h1 className="fw-bold">
              Laporan Pelaksanaan Tata Kelola Perusahaan
            </h1>
          </div>
          <div className="col-12 col-lg-8">
            <p className="text-light fs-6">
              Tata Kelola yang baik adalah suatu tata cara pengelolaan Bank yang
              menerapkan prinsip-prinsip keterbukaan (transparency),
              akuntabilitas (accountability), pertanggungjawaban
              (responsibilty), dan kewajaran (fairness)
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-2">
            <select
              className="form-select py-2 text-light"
              aria-label="filter-year"
              onChange={(e) => setTahun(e.target.value)}
            >
              <option hidden value="">
                Pilih Tahun...
              </option>
              {dataYear &&
                dataYear.map((t, k) => {
                  return (
                    <option key={k} value={t?.name} className="text-dark">
                      Tahun {t?.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex align-items-stretch justify-content-between">
          {dataContent &&
            dataContent.map((konten, k) => {
              return (
                <div key={k} className="col-6 col-lg-4 p-3">
                  <div className="card" style={{ minHeight: 120 }}>
                    <div className="card-body position-relative">
                      <div className="row d-flex align-items-center">
                        <div className="col-2 px-2 text-center">
                          {/* <img src={konten?.image} alt="asd" className='w-100' /> */}
                          <BsFileEarmarkPdf
                            className="text-primary"
                            size={50}
                          />
                        </div>
                        <div className="col-10">
                          <h4 className="fw-bold" style={{ fontSize: 16 }}>{konten?.name}</h4>
                        </div>
                      </div>
                      <div
                        className="position-absolute end-0 bottom-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(konten?.file, "_blank");
                        }}
                      >
                        <BsDownload className="m-3 text-primary" size={24} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LaporanTataKelolaPerusahaan;
