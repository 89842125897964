import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import useFetch from "../../../configs/services/useFetch";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRightSquare } from "react-icons/bs";
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";
import Meta from "../../Utils/Meta";
import { themePagination } from "../../../utils/themePagination";

const Artikel = () => {
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  // const [productPerView, setproductPerView] = useState(4);
  const { i18n } = useTranslation();
  const [dataShow, setDataShow] = useState()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const limit = 4

  const { data: dataPage } = useFetch(
    "/about-us/article/page?lang=" + i18n.language
  );
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch(
    "/about-us/article?lang=" + i18n.language
  );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const handleDataShow = () => {
    dataContent && setTotal(Math.ceil(dataContent.datas.length / limit))
    const chunked = []
    for (let i = 0; i < dataContent.datas.length; i += limit) {
      chunked.push(dataContent.datas.slice(i, i + limit))
    }
    setDataShow(chunked[page])
  }

  useEffect(() => {
    dataContent?.datas && handleDataShow()
  }, [dataContent, page])


  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    // // console.log(productPerView);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // useEffect(() => {
  //   if (width <= 768) {
  //     setproductPerView(2);
  //   } else {
  //     setproductPerView(4);
  //   }
  // }, [width]);

  const metaValue = { title: dataPage ? '' + dataPage.title : 'Artikel', description: dataPage ? dataPage.description?.substr(0, 255) : 'ini deskripsi budaya kerja', ogimage: dataPage ? dataPage?.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div className="my-5">
      <Meta data={metaValue} />
      <div className="">
        <img src={dataPage?.image} className="w-100" alt="article" />
      </div>
      <div className="container">
        <div className="mt-5">
          <div className="">
            <h1 className="fw-bold mb-5">{dataPage?.title}</h1>
            <p className="text-light fs-6" style={{ textAlign: "justify" }}>
              {dataPage?.description}
            </p>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="row">
            {dataShow &&
              dataShow.map((artikel, k) => {
                return (
                  <div key={k} className="col-12 col-md-4 col-lg-3">
                    <div
                      className="position-relative rounded-3"
                      style={{
                        height: 350,
                        backgroundImage: `url(${artikel?.media?.thumbnail})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div
                        onClick={() => navigate(`/artikel/${artikel?.slug}`)}
                        className="position-absolute top-0 start-0 w-100 h-100 p-3 bg-gr-secondary-v rounded-3 d-flex align-items-end justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <h4 className="fw-bold text-white">{artikel?.title}</h4>
                      </div>
                    </div>
                  </div>
                );
              })}

            {total > 1 &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <ThemeProvider theme={themePagination}>
                  <Pagination count={total} className="custom-pagination" color="primary" page={page + 1} onChange={(e, v) => setPage(v - 1)} shape="rounded" />
                </ThemeProvider>
              </div>
            }
          </div>
          {/* <Swiper
          slidesPerView={productPerView}
          spaceBetween={20}
          scrollbar={{
            hide: false,
            draggable: true,
            dragSize: 150
          }}
          modules={[Scrollbar]}
        >
          {dataContent && dataContent?.datas.map((artikel, k) => {
            return (
              <SwiperSlide key={k} className="position-relative rounded-3" style={{ height: 350, backgroundImage: `url(${artikel?.media?.thumbnail})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <div onClick={()=> navigate(`/artikel/${artikel?.id}`)} className="position-absolute top-0 start-0 w-100 h-100 bg-gr-secondary-v rounded-3 d-flex align-items-end justify-content-center" style={{cursor: 'pointer'}}>
                  <h4 className="fw-bold text-white">{artikel?.title}</h4>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper> */}
          {/* <Link to={`/artikel`} className="float-end fw-bold text-decoration-none">lihat lainnya ... <BsArrowRightSquare /> </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Artikel;
