import React from 'react'
import { useTranslation } from 'react-i18next';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate, useParams } from "react-router-dom";
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const DetailCSR = () => {
    const { content } = useParams();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { data: dataDetail } = useFetch("/about-us/csr/" + content + "?lang=" + i18n.language);

    const metaValue = { title: dataDetail ? '' + dataDetail.title : 'Detail CSR', description: dataDetail ? limitText(dataDetail.description, 255) : 'Detail CSR', ogimage: dataDetail ? dataDetail.media.preview : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
    return (
        <div className="container-fluid mt-5 pt-2">
            <Meta data={metaValue} />
            <div className="row">
                <div className="col-lg-1">
                    <div
                        onClick={() => navigate(-1)}
                        className="position-absolute top-0 start-0 mt-5 p-1 pointer"
                    >
                        <BsArrowLeftShort
                            fontSize={62}
                            className="text-secondary"
                        />
                    </div>
                </div>
                <div className="col-lg-7 col-xl-6 mt-5 pe-xl-5" style={{ minHeight: 500 }}>
                    <div className="me-lg-5 pe-xl-5">
                        <h1 className="fw-bolder text-secondary mb-4">
                            {dataDetail?.title}
                        </h1>
                        <p
                            className="fs-6 text-dark"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: dataDetail?.description }}
                        >
                        </p>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-5 p-0" style={{ height: 500 }}>
                    <div style={{
                        backgroundImage: `url(${dataDetail?.media?.original})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100%',
                        width: '100%'
                    }} />
                </div>
            </div>
        </div>
    )
}

export default DetailCSR