import React from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import Meta from "../../Utils/Meta";

const DewanPengawasanSyariah = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/about-us/board-syariah/page?lang=" + i18n.language
  );
  const { data: dataContent } = useFetch(
    "/about-us/board-syariah?lang=" + i18n.language
  );
  const metaValue = { title: dataPage ? '' + dataPage.title : 'Dewan Pengawas Syariah', description: dataPage ? dataPage.description?.substr(0, 255) : 'Dewan Pengawas Syariah', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h1 className="fw-bold">{dataPage?.title}</h1>
          </div>
          <div
            className="col-12 col-lg-8 fs-6 text-light"
            style={{
              textAlign: "justify",
            }}
          >
            {dataPage?.description}
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="row">
          <div className="col"></div>
          <div className="col-12 col-lg-11">
            <div className="row d-flex justify-content-center">
              {dataContent &&
                dataContent.map((konten, k) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4">
                      <div
                        className="w-100 m-2 p-3 text-center d-flex flex-column justify-content-between"
                        data-bs-toggle="modal"
                        data-bs-target={`#modal-${konten?.id}`}
                      >
                        <div>
                          <img
                            src={konten?.media?.original}
                            alt={konten?.image}
                            className="rounded-circle mb-3"
                            style={{ height: 200 }}
                          />
                          <h4 className="fw-bold">{konten?.nama}</h4>
                        </div>
                        <p>{konten?.jabatan}</p>
                      </div>
                      <div
                        className="modal modal-lg fade"
                        id={`modal-${konten?.id}`}
                        tabindex="-1"
                        aria-labelledby={`modal-${konten?.id}Label`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content py-3">
                            <div className="modal-body">
                              <button
                                type="button"
                                className="btn-close float-end"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                              <div className="d-flex align-items-center mx-5">
                                <div
                                  className="rounded-circle"
                                  style={{
                                    width: 100,
                                    height: 100,
                                    backgroundImage: `url(${konten?.media?.original})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                                <h4 className="ms-2 fw-bold">{konten?.nama}</h4>
                              </div>
                              <div
                                className="mx-5 mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: konten?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

export default DewanPengawasanSyariah;
