import React from 'react'
import { useTranslation } from 'react-i18next';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate, useParams } from "react-router-dom";
import useFetch from '../../configs/services/useFetch';
import Meta from '../Utils/Meta';

const SiaranPersDetail = () => {
    const navigate = useNavigate();
    const { content } = useParams();
    const { i18n } = useTranslation();
    const { data: dataPage } = useFetch("/hubungan-investor/siaran-pers/" + content + "?lang=" + i18n.language);

    const metaValue = { title: 'Siaran Pers', description: 'Siaran Pers', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
    return (
        <div style={{ marginTop: 60 }}>
            <Meta data={metaValue} />
            <div className="container">
                <div className="row">
                    <div className="col-12 position-relative">
                        <div
                            onClick={() => navigate(-1)}
                            className=" pointer"
                        >
                            <BsArrowLeftShort
                                fontSize={62}
                                className="text-primary"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-1"></div>
                    <div className="col-12 col-lg-10 ">
                        <img src={dataPage?.media?.original} alt=".." className='w-100' />
                        <h1 className='fw-bold my-5' >
                            {dataPage?.title}
                        </h1>
                        <p className='text-dark fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.description }}></p>
                    </div>
                    <div className="col-12 col-lg-1"></div>
                </div>
            </div>
        </div>
    )
}

export default SiaranPersDetail