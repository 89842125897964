import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';

const BudayaKerja = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/profile/budaya-kerja/page?lang=" + i18n.language);
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch("/profile/budaya-kerja?lang=" + i18n.language);
  const metaValue = { title: 'Budaya Kerja', description: dataPage ? dataPage?.description?.substr(0, 255) : 'ini deskripsi budaya kerja', ogimage: dataPage ? dataPage?.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="row text-center mb-3">
          <div className="col"></div>
          <div className="col-12 col-lg-6">
            <h1 className='fw-bold'>
              {dataPage?.title}
            </h1>
          </div>
          <div className="col"></div>
        </div>
        <div className="row text-center mb-5" >
          <div className="col"></div>
          <div className="col-12 col-lg-6">
            <p className='mb-0'>
              {dataPage?.description}
            </p>
          </div>
          <div className="col"></div>
        </div>

        <div className="text-center mb-3">
          <div className="">
            <img src={dataPage?.image} className="w-100" style={{ "maxWidth": "400px" }} alt="img-budaya-kerja" />
          </div>
        </div>

        <div className="row mt-5">
          {
            dataContent && dataContent?.datas.map((konten, k) => {
              return (
                <div key={k} className="col-12 col-md-6 col-lg-4 p-3">
                  <div className="text-center" style={{ "overflow": "hidden" }}>
                    <img src={konten?.media?.original} alt={konten?.image} className="w-auto" style={{ "max-height": "200px", "objectFit": "cover" }} />
                    <h4 className='fw-bold mt-3'>
                      {konten?.title}
                    </h4>
                    <p className='text-truncate'>{konten?.short_description}...</p>
                    <div className="text-secondary fs-6 fw-bold mt-3" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={`#modal-${konten?.id}`}>
                      Lanjutkan Baca..
                    </div>
                  </div>
                  <div className="modal modal-lg fade" id={`modal-${konten?.id}`} tabindex="-1" aria-labelledby={`modal-${konten?.id}Label`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content py-3">
                        <div className="modal-body">
                          <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div className='d-flex align-items-center mx-5'>
                            {/* <div className='rounded-circle' style={{ width: 100, height: 100, backgroundImage: `url(${konten?.media?.preview})`, backgroundSize: 'cover', backgroundPosition: "center" }} /> */}
                            <h4 className='ms-2 fw-bold'>{konten?.title}</h4>
                          </div>
                          <div className='mx-5 mt-4' dangerouslySetInnerHTML={{ __html: konten?.description }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }


        </div>
      </div>
    </div>
  )
}

export default BudayaKerja