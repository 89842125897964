import React from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../configs/services/useFetch";
import Meta from "../../Utils/Meta";

const VisiMisi = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch(
    "/about-us/visi-misi/page?lang=" + i18n.language
  );
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  // const { data: dataContent } = useFetch(
  //   "/about-us/visi-misi?lang=" + i18n.language
  // );
  const metaValue = { title: dataPage ? '' + dataPage?.title : 'Detail', description: dataPage ? dataPage?.description1.substr(0, 255) : 'ini deskripsi detail', ogimage: dataPage ? dataPage?.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div className="bg-white">
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="">
          <div className="">
            <img src={dataPage?.image} className="w-100" alt="visi-misi" />
          </div>
          <div className="mt-5">
            <h1 className="fw-bold mb-3">{dataPage?.title}</h1>
            <p className="text-light fs-6" style={{ textAlign: "justify" }}>
              {dataPage?.description1}
            </p>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <nav>
            <div
              className="nav nav-tabs w-100 tk border-0"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link active"
                id={`nav-visi-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#nav-visi`}
                type="button"
                role="tab"
                aria-controls="nav-visi"
                aria-selected="true"
              >
                Visi
              </button>
              <span style={{ width: 10 }} />
              <button
                className="nav-link"
                id={`nav-misi-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#nav-misi`}
                type="button"
                role="tab"
                aria-controls="nav-misi"
                aria-selected="true"
              >
                Misi
              </button>
            </div>
          </nav>
          <div
            className="tab-content bg-white border-0 promo-content shadow"
            id="nav-tabContent"
          >
            <div
              className="tab-pane pb-5 fade show p-4 active"
              id={`nav-visi`}
              role="tabpanel"
              aria-labelledby={`nav-visi-tab`}
            >
              <p dangerouslySetInnerHTML={{ __html: dataPage?.description2 }} />
            </div>
            <div
              className="tab-pane pb-5 fade show p-4"
              id={`nav-misi`}
              role="tabpanel"
              aria-labelledby={`nav-misi-tab`}
            >
              <p dangerouslySetInnerHTML={{ __html: dataPage?.description3 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisiMisi;
