import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const ManagementBankDKI = () => {
    return (
        <>
            <div className="container text-center mt-5 pt-5">
                <NavLink to={`/tentang-kami/management-bank-dki/dewan-komisaris`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Dewan Komisaris</NavLink>
                <NavLink to={`/tentang-kami/management-bank-dki/direksi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Direksi</NavLink>
                <NavLink to={`/tentang-kami/management-bank-dki/dewan-pengawasan-syariah`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Dewan Pengawas Syariah</NavLink>
                <NavLink to={`/tentang-kami/management-bank-dki/komite`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Komite</NavLink>
                <NavLink to={`/tentang-kami/management-bank-dki/pejabat-eksekutif`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Pejabat Eksekutif</NavLink>
            </div>
            <Outlet />
        </>
    )
}

export default ManagementBankDKI