import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';

const StrukturOrganisasi = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/profile/struktur-organisasi/page?lang=" + i18n.language);
  const metaValue = { title: 'Struktur organisasi', description: 'ini deskripsi struktur organisasi', ogimage: dataPage ? dataPage.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container text-center my-5">
        <h1 className='fw-bold mb-5'>Struktur Organisasi</h1>
        <img src={dataPage?.image} className="w-100" alt="struktur-organisasi-img" />
      </div>
    </div>
  )
}

export default StrukturOrganisasi