import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import { IoChevronDownCircle } from "react-icons/io5";
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const Gratifikasi = () => {
  const [selected, setSelected] = useState(0);
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/tata-kelola-perusahaan/gratifikasi?lang=" + i18n.language);
  // const { data: dataContent } = useFetch("/tata-kelola-perusahaan/manajemen-risiko?lang=" + i18n.language);
  const metaValue = { title: dataPage ? '' + dataPage.title : 'Gratifikasi', description: dataPage ? limitText(dataPage.description, 255) : 'Gratifikasi', ogimage: dataPage ? dataPage.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container mt-3">
        <img src={dataPage?.image} alt="gratifikasi" className='w-100 mt-3' />
        <h1 className='fw-bold mt-3'>
          {dataPage?.title}
        </h1>
        <p className='text-light fs-6 mt-4' dangerouslySetInnerHTML={{ __html: dataPage?.description }}>
        </p>
      </div>
      <div className="container my-5">
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 0 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-pelaporan`}
            // aria-expanded="false"
            // aria-controls={`collapse-pelaporan`}
            aria-expanded={selected == 0 ? "true" : "false"}
            onClick={() => setSelected(0)}
          >
            Penyampaian Pelaporan Gratifikasi
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 0 && <div className="px-3">
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Penyampaian Pelaporan Gratifikasi</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.pelaporan }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </p>
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 1 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-pelaporan`}
            // aria-expanded="false"
            // aria-controls={`collapse-pelaporan`}
            aria-expanded={selected == 1 ? "true" : "false"}
            onClick={() => setSelected(1)}
          >
            Sanksi Hukum
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 1 && <div className="px-3">
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Sanksi Hukum</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.sanksi }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </p>
        <p className="mt-2">
          <button
            className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 2 ? "bg-primary text-white" : "bg-white text-secondary")}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-pelaporan`}
            // aria-expanded="false"
            // aria-controls={`collapse-pelaporan`}
            aria-expanded={selected == 2 ? "true" : "false"}
            onClick={() => setSelected(2)}
          >
            Manfaat Melaporkan Gratifikasi
            <IoChevronDownCircle className="fs-2" />
          </button>
          {selected == 2 && <div className="px-3">
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="row">
                  <div className="container px-5 pt-5">
                    <h4 className='fw-bold my-4'>Manfaat Melaporkan Gratifikasi</h4>
                    <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.manfaat }}></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </p>
      </div>
    </div>
  )
}

export default Gratifikasi