import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDownload, BsFileEarmarkPdf, BsFillCalendarWeekFill } from "react-icons/bs";
import { IoChevronDownCircle } from "react-icons/io5";
import useFetch from "../../../configs/services/useFetch";
import Meta from "../../Utils/Meta";

const InformasiSahamObligasi = () => {
  const { i18n } = useTranslation();
  const [tahun, setTahun] = useState(0);
  const [selected, setSelected] = useState(0);
  const { data: dataCategory } = useFetch(
    "/hubungan-investor/informasi-saham-obligasi/category"
  );
  const { data: dataContent } = useFetch(
    "/hubungan-investor/informasi-saham-obligasi?lang=" +
    i18n.language +
    "&year=" +
    tahun +
    ""
  );
  const { data: dataContentInfoKepemilikanSaham } = useFetch(
    "/hubungan-investor/informasi-kepemilikan-saham?lang=" + i18n.language
  );
  const { data: dataContentObligasi } = useFetch(
    "/hubungan-investor/obligasi?lang=" + i18n.language
  );
  const { data: dataContentProfesiPenunjang } = useFetch(
    "/hubungan-investor/profesi-penunjang-pasar-modal?lang=" + i18n.language
  );
  const { data: dataContentRapatUmumPS } = useFetch(
    "/hubungan-investor/rapat-umum-pemegang-saham?lang=" + i18n.language
  );
  const { data: dataContentProspektus } = useFetch(
    "/hubungan-investor/prospektus?lang=" + i18n.language
  );
  // const { data: dataContentObligasi } = useFetch("/hubungan-investor/obligasi?lang=" + i18n.language);

  // console.log("page: ", dataContentProspektus);

  useEffect(() => {
    if (dataCategory && dataCategory[0]) {
      setTahun(dataCategory[0].name);
    }
  }, [dataCategory]);

  const metaValue = { title: 'Informasi Saham dan Obligasi', description: 'Informasi Saham dan Obligasi', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container py-5">
        <h1 className="fw-bold text-center">Informasi Saham dan Obligasi</h1>
        <div className="text-center">
          <p className="text-light fs-6 mx-5 px-5">
            Berikut adalah informasi mengenai kepemilikan informasi saham bank
            DKI dan Obligasi yang tleah diterbitkan oleh Bank DKI
          </p>

          {dataCategory &&
            dataCategory.map((c, k) => (
              <button
                key={k}
                onClick={() => setTahun(c?.name)}
                className={
                  c?.name === tahun
                    ? "btn fw-bold btn-secondary px-4 py-2 mx-1"
                    : "btn fw-bold text-secondary bg-tf-primary px-4 py-2 mx-1"
                }
              >
                {c?.name}
              </button>
            ))}
        </div>

        <div className="row my-5">
          {dataContent &&
            dataContent?.map((c, k) => {
              return (
                <div key={k} className="col-6">
                  <div className="card shadow">
                    <div className="card-body position-relative">
                      <div className="row">
                        <div className="col-4 px-2 text-center">
                          {/* <img src={c?.image} alt="asd" className='w-100' /> */}
                          <BsFillCalendarWeekFill
                            className="text-primary"
                            size={78}
                          />
                        </div>
                        <div className="col-8">
                          <h4 className="fw-bold">{c?.name}</h4>
                        </div>
                      </div>
                      <div
                        className="position-absolute end-0 bottom-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(c?.file, "_blank");
                        }}
                      >
                        <BsDownload className="m-3 text-primary" size={24} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <h1 className="fw-bold text-center">Informasi Kepemilikan Saham</h1>
        <p
          style={{ textAlign: 'justify' }}
          className="text-light fs-6"
          dangerouslySetInnerHTML={{
            __html: dataContentInfoKepemilikanSaham?.description,
          }}
        ></p>
        <h1 className="fw-bold text-center mt-5">Obligasi</h1>
        <p
          style={{ textAlign: 'justify' }}
          className="text-light fs-6"
          dangerouslySetInnerHTML={{ __html: dataContentObligasi?.description }}
        ></p>
        <h1 className="fw-bold text-center">Profesi Penunjang Pasar Modal</h1>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {dataContentProfesiPenunjang &&
              dataContentProfesiPenunjang.map((p, k) => {
                return (
                  <div key={k} className="col-12 col-lg-5 p-3 h-100">
                    <div className="w-100 h-100 rounded-4 border border-2 p-3 text-center">
                      <h4 className="fw-bold">{p.title}</h4>
                      <p
                        style={{ textAlign: 'justify' }}
                        className="text-light fs-6"
                        dangerouslySetInnerHTML={{ __html: p.description }}
                      ></p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <h1 className="fw-bold text-center">Rapat Umum Pemegang Saham</h1>
        <p
          style={{ textAlign: 'justify' }}
          className="text-light fs-6"
          dangerouslySetInnerHTML={{
            __html: dataContentRapatUmumPS?.description,
          }}
        ></p>
        <span className="fs-6 fw-bold text-light">
          {dataContentRapatUmumPS?.name1} -{" "}
          {dataContentRapatUmumPS?.file1 &&
            <a
              href={dataContentRapatUmumPS?.file1}
              className="fs-6 text-decoration-none fw-bold"
            >
              Download PDF
            </a>}
        </span>
        <br />
        <span className="fs-6 fw-bold text-light">
          {dataContentRapatUmumPS?.name2} -{" "}
          {dataContentRapatUmumPS?.file2 &&
            <a
              href={dataContentRapatUmumPS?.file2}
              className="fs-6 text-decoration-none fw-bold"
            >
              Download PDF
            </a>
          }
        </span>
        <br />
        <span className="fs-6 fw-bold text-light">
          {dataContentRapatUmumPS?.name3} -{" "}
          {dataContentRapatUmumPS?.file3 &&
            <a
              href={dataContentRapatUmumPS?.file3}
              className="fs-6 text-decoration-none fw-bold"
            >
              Download PDF
            </a>}
        </span>
        <br />
        <span className="fs-6 fw-bold text-light">
          {dataContentRapatUmumPS?.name4} -{" "}
          {dataContentRapatUmumPS?.file4 &&
            <a
              href={dataContentRapatUmumPS?.file4}
              className="fs-6 text-decoration-none fw-bold"
            >
              Download PDF
            </a>}
        </span>
        <br />
        <span className="fs-6 fw-bold text-light">
          {dataContentRapatUmumPS?.name5} -{" "}
          {dataContentRapatUmumPS?.file5 &&
            <a
              href={dataContentRapatUmumPS?.file5}
              className="fs-6 text-decoration-none fw-bold"
            >
              Download PDF
            </a>}
        </span>
        <br />
        <h1 className="fw-bold text-center mt-5">Prospektus</h1>
        <div>
          <p className="mt-5">
            <button
              className={"btn border border-1 w-100 rounded-4 fw-bold d-flex justify-content-between align-items-center mb-0 p-3 px-4 " + (selected == 0 ? "bg-primary text-white" : "bg-white text-secondary")}
              type="button"
              // data-bs-toggle="collapse"
              // data-bs-target={`#collapse-${ca?.name}`}
              aria-expanded={selected == 0 ? "true" : "false"}
              onClick={() => setSelected(0)}
            >
              Prospektus Bank DKI
              <IoChevronDownCircle className="fs-2" />
            </button>
          </p>
          {selected == 0 && <div className='px-3'>
            <div className="h-100">
              <div className="w-100 h-100">
                <div className="container">
                  <div className="row">
                    {dataContentProspektus &&
                      dataContentProspektus.map((c, k) => {
                        return (
                          <div key={k} className="col-12 col-lg-4 p-3">
                            <div className="card" style={{ minHeight: 120 }}>
                              <div className="card-body position-relative">
                                <div className="row">
                                  <div className="col-2 px-2 text-center">
                                    {/* <img src={c?.image} alt="asd" className='w-100' /> */}
                                    <BsFileEarmarkPdf className="text-primary" size={50} />
                                  </div>
                                  <div className="col-10">
                                    <h5 style={{ fontSize: 16 }} className='fw-bold'>{c?.name}</h5>
                                  </div>
                                </div>
                                <div className="position-absolute end-0 bottom-0" style={{ cursor: "pointer" }} onClick={() => { window.open(c?.file, '_blank') }}>
                                  <BsDownload className='m-3 text-primary' size={24} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default InformasiSahamObligasi;
