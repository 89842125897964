import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const HubunganInvestor = () => {
    return (
        <>
            <div className="container text-center mt-5 pt-5">
                <NavLink to={`/tentang-kami/hubungan-investor/informasi-keuangan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Informasi Keuangan</NavLink>
                <NavLink to={`/tentang-kami/hubungan-investor/laporan-tahunan-dan-berkelanjutan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Laporan Tahunan & berkelanjutan</NavLink>
                <NavLink to={`/tentang-kami/hubungan-investor/informasi-saham-dan-obligasi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Informasi Saham & Obligasi</NavLink>
                <NavLink to={`/tentang-kami/hubungan-investor/pengumuman-dan-publikasi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Pengumuman & Publikasi</NavLink>
                <NavLink to={`/tentang-kami/hubungan-investor/siaran-pers`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Siaran Pers</NavLink>
            </div>
            <Outlet />
        </>
    )
}

export default HubunganInvestor