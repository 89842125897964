import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const ProfilBankDKI = () => {
    // const {pathname} = useLocation();
    // // console.log(pathname)

    return (
        <>
            <div className="container text-center mt-5 pt-5">
                <NavLink to={`/tentang-kami/profil-bank-dki/sejarah-profil-bank-dki`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Sejarah & Profil Bank DKI</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/visi-misi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Visi & Misi</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/budaya-kerja`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Budaya Kerja</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/identitas-perusahaan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Identitas Perusahaan</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/struktur-organisasi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Struktur Organisasi</NavLink>
                <br/>
                <NavLink to={`/tentang-kami/profil-bank-dki/lembaga-profesi`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Lembaga & Profesi</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/artikel`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Artikel</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/struktur-grup-perusahaan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Struktur Grup Perusahaan</NavLink>
                <NavLink to={`/tentang-kami/profil-bank-dki/penghargaan`} className='btn btn-secondary bg-subnav-ttkami border-0 rounded-0 fw-bold fs-6 m-1'>Penghargaan</NavLink>
            </div>
            {/* <div style={{height: 200}} /> */}
            <Outlet />
        </>
    )
}

export default ProfilBankDKI