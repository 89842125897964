import React from 'react'
import { Helmet } from 'react-helmet';

const Meta = ({ data }) => {
    return (
        <Helmet>
            <title>{data?.title ?? ""}</title>
            <meta name="description" content={data?.description ?? ""} />
            <meta name="keywords" content={data?.keywords ?? ""} />
            <meta property="og:title" content={data?.title ?? ""} />
            <meta property="og:description" content={data?.description ?? ""} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data?.ogUrl ?? ""} />
            <meta property="og:image" content={data?.ogImage ?? ""} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={data?.title ?? ""} />
            <meta name="twitter:description" content={data?.description ?? ""} />
            <meta name="twitter:image" content={data?.ogImage ?? ""} />
            <meta name="twitter:image:src" content={data?.ogImage ?? ""} />
            <meta name="twitter:url" content={data?.ogUrl ?? ""} />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        </Helmet>
    )
}

export default Meta