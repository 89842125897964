import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import LogoCodeOfConduct from '../../../assets/img/code-of-conduct.png'
import LogoKodeEtik from '../../../assets/img/kode-etik.png'
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const EtikaBisnisKodeEtik = () => {
  const { i18n } = useTranslation();
  const { data: dataContent } = useFetch("/tata-kelola-perusahaan/etika-bisnis-kode-etik?lang=" + i18n.language);

  const metaValue = { title: dataContent ? '' + dataContent.title : 'Etika Bisnis & Kode Etik', description: dataContent ? limitText(dataContent?.description, 255) : 'Etika Bisnis & Kode Etik', ogimage: dataContent ? dataContent.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <img src={dataContent?.image} alt="img1" className='w-100 mb-5' />
        <p style={{ textAlign: 'justify' }} className='text-light fs-6 mb-5' dangerouslySetInnerHTML={{ __html: dataContent?.description }}></p>
        <a href={dataContent?.file} target="_blank" rel="noreferrer" style={{ cursor: "pointer" }} className='text-secondary fw-bold text-decoration-none fs-6'> <i>Code of Conduct (file download)</i> </a>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 p-3">
            <div className="card  h-100 shadow-lg">
              <div className="card-body">
                <div className="text-center">
                  <img src={LogoCodeOfConduct} alt="code-of-conduct" width={78} />
                  <h4 className='fw-bold my-3'>Pokok Pokok Code of Conduct</h4>
                </div>
                <p style={{ textAlign: 'justify' }} className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataContent?.description1 }}></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 p-3">
            <div className="card  h-100 shadow-lg">
              <div className="card-body">
                <div className="text-center">
                  <img src={LogoKodeEtik} alt="code-of-conduct" width={78} />
                  <h4 className='fw-bold my-3'>Penyebaran Kode Etik</h4>
                </div>
                <p style={{ textAlign: 'justify' }} className='text-light fs-6' dangerouslySetInnerHTML={{ __html: dataContent?.description2 }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EtikaBisnisKodeEtik