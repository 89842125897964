import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';

const LembagaProfesi = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/profile/lembaga-profesi/page?lang=" + i18n.language);
  // const { data: dataCategory } = useFetch("/career/category?lang=" + i18n.language);
  const { data: dataContent } = useFetch("/profile/lembaga-profesi?lang=" + i18n.language);

  const metaValue = { title: dataPage ? '' + dataPage.title : 'Lembaga profesi', description: dataPage ? dataPage.description?.substr(0, 255) : 'ini deskripsi lembaga profesi', ogimage: dataPage ? dataPage?.image : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  return (
    <div>
      <Meta data={metaValue} />
      <img src={dataPage?.image} className="w-100 my-5" alt="lembaga-profesi" />
      <div className="container">
        <h1 className='fw-bold mt-5'>
          {dataPage?.title}
        </h1>
        <p className='text-light mt-4 fs-6' dangerouslySetInnerHTML={{ __html: dataPage?.description }}></p>
      </div>
      <div className="container">
        <div className="row">
          {
            dataContent && dataContent.map((konten, k) => {
              return (
                <div key={k} className="col-12 col-md-6 col-lg-4 p-3">
                  <div className="card h-100">
                    <div className="card-body text-center d-flex flex-column justify-content-between align-items-center">
                      <h4 className='fw-bold mt-2'>
                        {konten?.title}
                      </h4>
                      <img src={konten?.media?.original} alt={konten?.image} className="w-75 my-2" />
                      <p className='text-light fs-6' dangerouslySetInnerHTML={{ __html: konten?.description }}></p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default LembagaProfesi