import React from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import Icon from "../../assets/img/BackToTop.svg";

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
             in place of 'smooth' */
    });
  };
  return (
    <div
      style={{
        position: "fixed",
        bottom: 20,
        right: 40,
        zIndex: 999999,
        cursor: 'pointer'
      }}
    >
      <img src={Icon} alt="..." width={52} onClick={scrollToTop} />
    </div>
  );
};

export default BackToTop;
