import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BG1 from "../../assets/img/deposito-bg.jpg";
import useFetch from "../../configs/services/useFetch";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Meta from "../Utils/Meta";

const Deposito = () => {
  const [Deposito, setDeposito] = useState();
  const [Tenor, setTenor] = useState();
  const [Dana, setDana] = useState(0);
  const [Bunga, setBunga] = useState('');
  // const [BungaGross, setBungaGross] = useState(0);
  // const [BungaNet, setBungaNet] = useState(0);
  const [DisableHitung, setDisableHitung] = useState(false)
  const [hitung, setHitung] = useState(false)
  let jangkaWaktu = Number(String(Tenor?.name?.toLowerCase()).replace(' bulan', ''))
  let totalBunga = (Dana && Bunga && Tenor?.name) ? (((((Bunga / 100) * Dana) / 365) * 30) * Number(String(Tenor?.name.toLowerCase()).replace(' bulan', ''))) : ''
  const { i18n } = useTranslation();
  const { data: dataDeposito } = useFetch(
    "/calculator/jenis-deposito?lang=" + i18n.language
  );
  const [namaDeposito, setNamaDeposito] = useState('')
  const { data: dataTenor } = useFetch("/calculator/tenor");
  const metaValue = { title: 'Simulasi Deposito', description: 'Simulasi Deposito', ogimage: 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }
  const [result, setResult] = useState({ bunga: '', saldoBunga: '' })
  useEffect(() => {
    if (Tenor && Deposito) {
      axios
        .get(
          `${process.env.REACT_APP_API}api/v1/calculator/suku-bunga?tenor=${Tenor?.id}&deposito=${Deposito}`
        )
        .then((res) => {
          setBunga(res.data.data)
          setDisableHitung(false)
        })
        .catch((err) => {
          setDisableHitung(true)
          setBunga('')
        });
    }
  }, [Tenor, Deposito]);

  const HitungAkhir = (e) => {
    setHitung(true)
    setResult({ bunga: totalBunga, saldoBunga: Number((Dana + totalBunga).toFixed(2)) })
    e.preventDefault();
    if (!Deposito) {
      alert("Deposito Belum dipilih");
      return;
    }
    if (!Dana) {
      alert("Penempatan Dana Belum diinputkan");
      return;
    }
    if (!Tenor) {
      alert("Jangka Waktu Belum dipilih");
      return;
    }
    let tmpGross = (((Bunga / 100) * Dana) / 365) * 30;
    let tmpNett = tmpGross * 0.8;
    // setBungaGross(`Rp ${Math.round(tmpGross).toLocaleString("EN")}`);
    // setBungaNet(`Rp ${Math.round(tmpNett).toLocaleString("EN")}`);
  };
  const resetForm = (e) => {
    setHitung(false)
    setNamaDeposito('')
    e.preventDefault();
    setDeposito("");
    setTenor();
    setDana(0);
    setBunga(0);
    // setBungaGross(0);
    // setBungaNet(0);
    dataTenor && setTenor('')
  };

  const findNameById = (id) => {
    const option = dataTenor.find((option) => Number(option.id) === Number(id));
    setTenor(option)
    return option ? option?.name : '';
  };

  const handleFindNameDeposito = (id) => {
    const data = dataDeposito?.find(data => Number(data.id) == Number(id))
    data.name ? setNamaDeposito(data.name) : setNamaDeposito('')
    return data.name
  }

  const handleValidation = () => {
    if (namaDeposito?.toLowerCase().includes('mobile')) {
      if (jangkaWaktu == 24) {
        if (Dana >= 100_000_000) {
          return { status: false, min: 100_000_000 }
        } else {
          return { status: true, min: 100_000_000 }
        }
      } else if (jangkaWaktu == 1 || jangkaWaktu == 3 || jangkaWaktu == 6 || jangkaWaktu == 12) {
        if (Dana >= 1_000_000) {
          return { status: false, min: 1_000_000 }
        } else {
          return { status: true, min: 1_000_000 }
        }
      } else {
        return { status: true, min: 0 }
      }
    } else if (namaDeposito?.toLowerCase().includes('counter')) {
      if (Dana >= 1_000_000) {
        return { status: false, min: 1_000_000 }
      } else {
        return { status: true, min: 1_000_000 }
      }
    } else return { status: false, min: 0 }
  }

  return (
    <div className="container-fluid mt-5 pt-2 bg-white">
      <Meta data={metaValue} />
      <div className="row">
        <div className="col-lg-7">
          <div className="mx-5">
            <h1 className="fw-bold mb-5">Kalkulator Finansial</h1>
            <div className="mb-5">
              <Link
                to="/kalkulator-finansial/deposito"
                className="btn btn-secondary me-2 px-3 py-2 fs-6 fw-bold"
              >
                Deposito
              </Link>
              <Link
                to="/kalkulator-finansial/kredit/kpr"
                className="btn btn-outline-secondary ms-2 px-3 py-2 fs-6  fw-bold"
              >
                KPR/KMG
              </Link>
            </div>
            <h4 className="fw-bold">Simulasi Deposito</h4>
            <div className="mt-5">
              <form style={{ position: 'relative' }}>
                <div className="row">
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      htmlFor="jenis-deposito"
                      className="form-label fw-bold text-light mb-0"
                      style={{ fontSize: 14 }}
                    >
                      Jenis Deposito
                    </label>
                    <select
                      name="jenis-deposito"
                      className="d-block w-100 rounded border-0 bg-info mt-3 p-2 fw-bold"
                      style={{ fontSize: 14 }}
                      id="jenis-deposito"
                      value={String(Deposito) == 'null' ? '' : Deposito}
                      onChange={(e) => {
                        { setDeposito(e.target.value); handleFindNameDeposito(e.target.value) }
                        // setDataTenor(JSON.parse(e.target.value).bunga);
                      }}
                    >
                      <option value="" hidden>
                        Pilih...{" "}
                      </option>
                      {dataDeposito &&
                        dataDeposito.map((d, k) => {
                          return (
                            <option key={k} value={d.id}>
                              {d.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      htmlFor="tenor"
                      className="form-label fw-bold text-light mb-0"
                      style={{ fontSize: 14 }}
                    >
                      Jangka Waktu
                    </label>
                    <select
                      name="tenor"
                      className="d-block w-100 rounded border-0 bg-info mt-3 p-2 fw-bold"
                      style={{ fontSize: 14 }}
                      id="tenor"
                      value={Tenor ? Tenor?.id : ''}
                      onChange={(e) => {
                        // setBunga(e.target.value);
                        findNameById(e.target.value);
                      }}
                    >
                      <option value="" hidden>
                        Pilih...{" "}
                      </option>
                      {dataTenor &&
                        dataTenor.map((d, k) => {
                          return (
                            <option key={k} value={d.id}>
                              {d.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      htmlFor="suku-bunga"
                      className="form-label fw-bold text-light mb-0"
                      style={{ fontSize: 14 }}
                    >
                      Suku Bunga
                    </label>
                    <NumericFormat
                      displayType="input"
                      decimalScale={2}
                      thousandSeparator={"."}
                      suffix={" %"}
                      decimalSeparator=","
                      className="d-block w-100 rounded border-0 bg-info mt-3 p-2 fw-bold"
                      style={{ fontSize: 14 }}
                      id="suku-bunga"
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue <= 100;
                      }}
                      disabled
                      value={Bunga}
                      onValueChange={(e) => setBunga(e.floatValue)}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 mb-4">
                    <label
                      htmlFor="jumlah"
                      className="form-label fw-bold text-light mb-0"
                      style={{ fontSize: 14 }}
                    >
                      Jumlah Penempatan Dana
                    </label>
                    <NumericFormat
                      displayType="input"
                      thousandSeparator={"."}
                      prefix={"Rp "}
                      decimalSeparator=","
                      allowNegative={false}
                      className="d-block w-100 rounded border-0 bg-info mt-3 p-2 fw-bold"
                      style={{ fontSize: 14 }}
                      id="jumlah"
                      value={Dana}
                      onValueChange={(e) => setDana(e.floatValue ?? 0)}
                    />
                    {handleValidation().min !== 0 && handleValidation().status && Tenor && Bunga && Deposito && !DisableHitung && <p style={{ color: 'red', fontSize: '14px', position: 'absolute' }}>
                      Jumlah Penempatan Dana Minimal Rp {(handleValidation().min).toLocaleString('id-ID')}
                    </p>}
                  </div>
                  <div className='col-md-6 col-lg-3 mb-4' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <button
                      type="input"
                      className='btn btn-danger fs-6 px-5 py-2 fw-bold rounded-4'
                      onClick={HitungAkhir}
                      disabled={handleValidation().status || !Tenor || !Bunga || !Deposito || DisableHitung}
                    >
                      Hitung
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-4" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <button
                      type="reset"
                      className="btn btn-outline-danger fs-6 px-4 py-2 fw-bold rounded-4"
                      onClick={resetForm}
                      disabled={!hitung}
                    >
                      Atur Ulang
                    </button>
                  </div>
                </div>
              </form>
              {hitung &&
                <>
                  <div className="form-label fw-bold text-light my-4 px-4 border-light" style={{ fontSize: 14, borderStyle: 'solid', padding: '20px 5px', borderWidth: '1px', borderRadius: '0.25rem' }}>
                    <div className="text-danger mb-2" style={{ opacity: 0.5 }}>Total Bunga</div>
                    <div className="text-danger" style={{ fontSize: '24px' }}>{result.bunga.toLocaleString('id-ID')}</div>
                    <div className="mt-4 text-danger mb-2" style={{ opacity: 0.5 }}>Saldo + Bunga</div>
                    <p className="text-danger" style={{ fontSize: '24px' }}>
                      {result.saldoBunga.toLocaleString('id-ID')}
                    </p>
                  </div></>}
              <p className="text-light"><b>Syarat dan Ketentuan</b></p>
              <ul style={{ paddingLeft: 18 }}>
                {['Simulasi ini merupakan ilustrasi perhitungan deposito, perhitungan sebenarnya mengikuti perhitungan di sistem Bank DKI.', 'Hasil pada perhitungan di atas belum termasuk pajak bunga (20%).', 'Suku bunga dapat berubah sewaktu-waktu sesuai ketentuan yang berlaku.'].map(e => (
                  <li className="text-light">{e}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className="col-lg-5"
          style={{
            backgroundImage: `url(${BG1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
        </div>
      </div>
    </div>
  );
};

export default Deposito;
