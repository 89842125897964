import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useFetch from "../../configs/services/useFetch";
import { NumericFormat } from "react-number-format";

const FormKPR = () => {
  const [Pekerjaan, setPekerjaan] = useState(60);
  const [Penghasilan, setPenghasilan] = useState();
  const [MaxAngsuran, setMaxAngsuran] = useState();
  const [Pengajuan, setPengajuan] = useState(0);
  const [Tenor, setTenor] = useState(0);
  // const [Bunga, setBunga] = useState(5.5);
  const [Lain, setLain] = useState(0);
  const [Angsuran, setAngsuran] = useState(null);
  const { data: dataPekerjaanKPR } = useFetch("/calculator/pekerjaan");
  const { data: Bunga } = useFetch("/calculator/kpr-kmg?type=kpr");
  const [sukuBunga, setSukuBunga] = useState(0)
  let estimasiAngsuranPerBulan = Math.round(((sukuBunga / 100) / 12) / (1 - (1 + ((sukuBunga / 100) / 12)) ** -(Number(Tenor) * 12)) * Pengajuan)
  const [result, setResult] = useState({ angsuran: '', penghasilan: '' })
  useEffect(() => {
    if (dataPekerjaanKPR) {
      setPekerjaan(dataPekerjaanKPR[0].dsr);
    }
  }, [dataPekerjaanKPR]);

  const resetForm = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    if (dataPekerjaanKPR) {
      setPekerjaan(dataPekerjaanKPR[0].dsr);
    }
    setPenghasilan("");
    setMaxAngsuran(0);
    setPengajuan(0);
    setTenor(0);
    setSukuBunga(0)
    // setBunga(5.5);
    setLain(0);
    setAngsuran(null);
  };

  const hitungAkhir = (e) => {
    window.scrollTo(0, 500)
    e.preventDefault();
    let jangkaWaktu = Number(Tenor) * 12;
    let SukuBunga = sukuBunga / 100;
    let hasil = Math.round(
      (Pengajuan * (1 + (SukuBunga / 12) * jangkaWaktu)) / jangkaWaktu
    );
    setAngsuran(hasil);
    hasil > 0 && setResult({ angsuran: hasil, penghasilan: estimasiAngsuranPerBulan })
  };

  useEffect(() => {
    let dsr = Pekerjaan / 100;
    let tmpMaxAngsuran = dsr * Penghasilan;
    setMaxAngsuran(tmpMaxAngsuran);
  }, [Penghasilan, Pekerjaan]);

  const handleRemoveZeroTenor = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/^0+/, '')
    if (!String(newValue).includes('.') && !String(e).includes('.')) {
      setTenor(newValue === '' ? '0' : newValue)
    }
  };


  const handleMaxPercent = (e) => {
    if (Number(e) <= 100) {
      setSukuBunga(e);
    }
  }

  return (
    <>
      <form>
        {/* <div className="mb-4 pt-2">
            <label
              htmlFor="pekerjaan"
              className="form-label fw-bold text-light mb-0"
              style={{ fontSize: 14 }}
            >
              Pekerjaan
            </label>
            <select
              name="pekerjaan"
              className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
              style={{ fontSize: 14 }}
              id="pekerjaan"
              value={Pekerjaan}
              onChange={(e) => setPekerjaan(e.target.value)}
            >
              {dataPekerjaanKPR &&
                dataPekerjaanKPR.map((d, k) => {
                  return (
                    <option key={k} value={d.dsr}>
                      {d.name}
                    </option>
                  );
                })}
            </select>
          </div> */}
        {/* <div className="mb-4 pt-2">
            <label
              htmlFor="penghasilan"
              className="form-label fw-bold text-light mb-0"
              style={{ fontSize: 14 }}
            >
              Penghasilan
            </label>
            <NumericFormat
              displayType="input"
              //   type="number"
              thousandSeparator={"."}
              prefix={"Rp "}
              decimalSeparator=","
              name="penghasilan"
              className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
              style={{ fontSize: 14 }}
              id="penghasilan"
              value={Penghasilan}
              onValueChange={changePenghasilan}
            />
          </div> */}
        <div
          className="fw-bold mb-4 pt-2 text-secondary"
          style={{ fontSize: 14 }}
        >
          {MaxAngsuran > 0
            ? `Maksimal Angsuran = Rp ${MaxAngsuran.toLocaleString("id-ID")}`
            : null}
        </div>
        <div className="mb-4 pt-2">
          <label
            htmlFor="pengajuan"
            className="form-label fw-bold text-light mb-0"
            style={{ fontSize: 14 }}
          >
            Plafon Pengajuan KPR
          </label>
          <NumericFormat
            displayType="input"
            name="pengajuan"
            thousandSeparator={"."}
            prefix={"Rp "}
            decimalSeparator=","
            className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
            style={{ fontSize: 14 }}
            id="pengajuan"
            value={Pengajuan}
            allowNegative={false}
            onValueChange={(e) => setPengajuan(e.floatValue)}
          />
        </div>
        <div className="mb-4 pt-2">
          <label
            htmlFor="tenor"
            className="form-label fw-bold text-light mb-0"
            style={{ fontSize: 14 }}
          >
            Jangka Waktu Kredit
          </label>
          <div className="input-group">
            <input
              type="number"
              name="tenor"
              className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
              style={{ fontSize: 14, appearance: 'none' }}
              id="tenor"
              min={0}
              value={Tenor}
              onChange={handleRemoveZeroTenor}
              pattern="[0-9]+"
              onKeyDown={(e) => {
                if (e.key === '-' || e.key === '.') {
                  e.preventDefault();
                }
              }}
            />
            <span
              className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
              id="basic-addon1"
            >
              tahun
            </span>
          </div>
        </div>
        <div className="mb-4 pt-2 row">
          <div className="col">
            <label
              htmlFor="bunga"
              className="form-label fw-bold text-light mb-0"
              style={{ fontSize: 14 }}
            >
              Suku Bunga
            </label>
            <div className="input-group">
              <NumericFormat
                name="bunga"
                displayType="input"
                thousandSeparator={"."}
                decimalSeparator=","
                className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
                style={{ fontSize: 14 }}
                id="bunga"
                decimalScale={2}
                value={sukuBunga}
                aria-describedby="group-bunga"
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                onValueChange={(e) => handleMaxPercent(e.floatValue ?? 0)}
              />
              {/* <input
            type="number"
            name="bunga"
            className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
            style={{ fontSize: 14 }}
            id="bunga"
            value={Bunga}
            aria-describedby="group-bunga"
            disabled
          // onChange={(e) => setBunga(e.target.value)}
          /> */}
              <span
                className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
                id="group-bunga"
              >
                %
              </span>
            </div>
          </div>
          <div className="col d-flex align-items-end">
            <div className="mx-auto">
              <button
                type="input"
                className='btn btn-danger fs-6 px-4 py-2 fw-bold me-2 rounded-3' style={{ cursor: "pointer" }}
                onClick={hitungAkhir}
                disabled={!Pengajuan || !Tenor || !sukuBunga}
              >
                Hitung
              </button>
              <button type="reset" className="btn btn-outline-danger fs-6 px-4 py-2 fw-bold ms-2 rounded-3" disabled={!Angsuran} onClick={resetForm}>
                Atur Ulang
              </button>
            </div>
          </div>
        </div>
      </form>
      {Angsuran && (
        <>
          <h4 className="fw-bold mt-4">Hasil Kalkulasi</h4>
          <div className="fw-bold text-light my-4 border-light px-4" style={{ fontSize: 14, borderStyle: 'solid', padding: '20px 5px', borderWidth: '1px', borderRadius: '0.25rem' }}>
            <div className="text-danger mb-2" style={{ opacity: 0.5 }}>Estimasi Angsuran Perbulan</div>
            <div className="text-danger" style={{ fontSize: '24px' }}>Rp {Number(Number(result.angsuran).toFixed(2)).toLocaleString('id-ID')}</div>
            {/* <div className="text-danger" style={{ fontSize: '24px' }}>Rp {Number(estimasiAngsuranPerBulan.toFixed(2)).toLocaleString("id-ID")}</div> */}
            <div className="mt-4 text-danger mb-2" style={{ opacity: 0.5 }}>Estimasi Penghasilan</div>
            <p className="text-danger" style={{ fontSize: '24px' }}>
              Rp {Number(Math.round(Math.ceil(result.penghasilan / 0.5 * Math.pow(10, -5)) / Math.pow(10, -5)).toFixed(2)).toLocaleString("id-ID")}
              {/* Rp {Number(Math.round(Math.ceil(estimasiAngsuranPerBulan / 0.5 * Math.pow(10, -5)) / Math.pow(10, -5)).toFixed(2)).toLocaleString("id-ID")} */}
            </p>

          </div>
        </>
      )}
      <p className="text-light"><b>Syarat dan Ketentuan</b></p>
      <ul style={{ paddingLeft: 18 }}>
        {['Perincian Kredit di atas hanya merupakan simulasi / estimasi biaya.', 'Perhitungan tersebut masih belum termasuk biaya lain-lain.', 'Setelah jangka waktu fixed rate berakhir, suku bunga yang berlaku adalah suku bunga floating rate.', 'Perhitungan di atas bersifat tidak mengikat.', 'Informasi Lanjut, silahkan hubungi Call Center / Kantor Cabang Bank DKI terdekat.'].map(e => (
          <li className="text-light">{e}</li>
        ))}
      </ul>
    </>
  );
};

export default FormKPR;
    // <form>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="pekerjaan"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Pekerjaan
    //     </label>
    //     <select
    //       name="pekerjaan"
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="pekerjaan"
    //       value={Pekerjaan}
    //       onChange={(e) => setPekerjaan(e.target.value)}
    //     >
    //       {dataPekerjaanKPR &&
    //         dataPekerjaanKPR.map((d, k) => {
    //           return (
    //             <option key={k} value={d.dsr}>
    //               {d.name}
    //             </option>
    //           );
    //         })}
    //     </select>
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="penghasilan"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Penghasilan
    //     </label>
    //     <NumericFormat
    //       displayType="input"
    //       //   type="number"
    //       thousandSeparator={"."}
    //       prefix={"Rp "}
    //       decimalSeparator=","
    //       name="penghasilan"
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="penghasilan"
    //       value={Penghasilan}
    //       onValueChange={changePenghasilan}
    //     />
    //   </div>
    //   <div
    //     className="fw-bold mb-4 pt-2 text-secondary"
    //     style={{ fontSize: 14 }}
    //   >
    //     {MaxAngsuran > 0
    //       ? `Maksimal Angsuran = Rp ${MaxAngsuran.toLocaleString("id-ID")}`
    //       : null}
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="pengajuan"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Plafon Pengajuan KPR
    //     </label>
    //     <NumericFormat
    //       displayType="input"
    //       name="pengajuan"
    //       thousandSeparator={"."}
    //       prefix={"Rp "}
    //       decimalSeparator=","
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="pengajuan"
    //       value={Pengajuan}
    //       onValueChange={(e) => setPengajuan(e.floatValue)}
    //     />
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="tenor"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Jangka Waktu Kredit
    //     </label>
    //     <div className="input-group">
    //       <input
    //         type="number"
    //         name="tenor"
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="tenor"
    //         value={Tenor}
    //         onChange={(e) => setTenor(e.target.value)}
    //       />
    //       <span
    //         className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
    //         id="basic-addon1"
    //       >
    //         /tahun
    //       </span>
    //     </div>
    //   </div>
    //   <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="bunga"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Suku Bunga
    //     </label>
    //     <div className="input-group">
    //       <input
    //         type="number"
    //         name="bunga"
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="bunga"
    //         value={sukuBunga}
    //         aria-describedby="group-bunga"
    //         // disabled
    //         onChange={(e) => setSukuBunga(e.target.value)}
    //       />
    //       {/* <input
    //         type="number"
    //         name="bunga"
    //         className="d-block text-dark form-control border-0 bg-info fw-bold mt-3 p-3"
    //         style={{ fontSize: 14 }}
    //         id="bunga"
    //         value={Bunga}
    //         aria-describedby="group-bunga"
    //         disabled
    //       // onChange={(e) => setBunga(e.target.value)}
    //       /> */}
    //       <span
    //         className="input-group-text mt-3 border-0 bg-info fw-bold text-dark"
    //         id="group-bunga"
    //       >
    //         %
    //       </span>
    //     </div>
    //   </div>
    //   {/* <div className="mb-4 pt-2">
    //     <label
    //       htmlFor="lain"
    //       className="form-label fw-bold text-light mb-0"
    //       style={{ fontSize: 14 }}
    //     >
    //       Jumlah Total Lainnya
    //     </label>
    //     <NumericFormat
    //       displayType="input"
    //       name="pengajuan"
    //       thousandSeparator={","}
    //       prefix={"Rp "}
    //       decimalSeparator="."
    //       //   name="lain"
    //       className="d-block text-dark w-100 rounded border-0 bg-info fw-bold mt-3 p-3"
    //       style={{ fontSize: 14 }}
    //       id="lain"
    //       value={Lain}
    //       onValueChange={(e) => setLain(e.floatValue)}
    //     />
    //   </div> */}

    //   {Angsuran ? (
    //     <>
    //       <h4 className="fw-bold">Hasil Kalkulasi</h4>
    //       <div className="fw-bold text-light mb-4" style={{ fontSize: 14 }}>
    //         <div className="row">
    //           <div className="col">Plafon Kredit</div>
    //           <div className="col">Rp {Pengajuan?.toLocaleString("id-ID")}</div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Jangka Waktu Kredit</div>
    //           <div className="col">{Tenor} Tahun</div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Bunga/Tahun (%)</div>
    //           <div className="col">
    //             {Bunga}% ({(Pengajuan * (Bunga / 100)).toLocaleString("id-ID")})
    //           </div>
    //         </div>
    //         <div className="row">
    //           <div className="col">Estimasi Angsuran Perbulan</div>
    //           <div className="col">Rp {Angsuran.toLocaleString("id-ID")}</div>
    //         </div>
    //         <div className="row text-primary text-center">
    //           {Angsuran > MaxAngsuran
    //             ? `Estimasi angsuran perbulan anda melebihi batas maksimal angsuran bulanan (Maksimal Angsuran = Rp ${MaxAngsuran.toLocaleString(
    //               "ID"
    //             )})`
    //             : null}
    //         </div>
    //       </div>
    //     </>
    //   ) : null}

    //   <div className="text-end mb-5">
    //     <button
    //       type="input"
    //       className="btn btn-danger fs-6 px-4 py-2 fw-bold me-2 rounded-3"
    //       onClick={hitungAkhir}
    //     >
    //       Hitung
    //     </button>
    //     <button
    //       type="reset"
    //       className="btn btn-outline-danger fs-6 px-4 py-2 fw-bold ms-2 rounded-3"
    //       onClick={resetForm}
    //     >
    //       Reset
    //     </button>
    //   </div>
    // </form>
