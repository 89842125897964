import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../configs/services/useFetch';
import Meta from '../../Utils/Meta';
import { limitText } from '../../../libs/testModification';

const SistemManajemenAntiPenyuapan = () => {
  const { i18n } = useTranslation();
  const { data: dataPage } = useFetch("/tata-kelola-perusahaan/sistem-manajemen-anti-penyuapan?lang=" + i18n.language);
  // const { data: dataContent } = useFetch("/tata-kelola-perusahaan/manajemen-risiko?lang=" + i18n.language);

  const metaValue = { title: dataPage ? '' + dataPage.title1 : 'Sistem Manajemen Anti Penyuapan', description: dataPage ? limitText(dataPage?.description1, 255) : 'Sistem Manajemen Anti Penyuapan', ogimage: dataPage ? dataPage.image1 : 'https://www.jamkrida-jakarta.co.id/wp-content/uploads/2018/11/akurat_20171002091348_5F7091.jpg' }

  return (
    <div>
      <Meta data={metaValue} />
      <div className="container my-5">
        <div className="">
          <div className="my-3">
            <img src={dataPage?.image1} alt="anti suap" className='w-100' />
          </div>
          <div className="my-3 py-5">
            <h1 className='fw-bold'>
              {dataPage?.title1}
            </h1>
            <p dangerouslySetInnerHTML={{ __html: dataPage?.description1 }} className='text-dark fs-6'></p>
          </div>
          <div className="my-3">
            <img src={dataPage?.image2} alt="integrasi management" className='w-100' />

          </div>
          <div className="my-3 py-5">
            <h1 className='fw-bold'>
              {dataPage?.title2}
            </h1>
            <p dangerouslySetInnerHTML={{ __html: dataPage?.description2 }} className='text-dark fs-6'></p>
            <a href={dataPage?.file} target="_blank" rel='noreferrer' className='btn btn-primary fs-6 fw-bold px-4 py-3'>View PDF</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SistemManajemenAntiPenyuapan